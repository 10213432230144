@use '../../abstracts/' as *;


.card.card--icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    background:none;
    box-shadow: none;

    .card__content {
        text-align: center;
        font-size:#{"clamp(1rem, 3vw, 1.25rem)"};

        strong {
            color:$color-primary-600;
        }

        *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
    &.swiper-slide {
        border-radius: 0;
        padding:0 24px 15px;
        box-shadow: none;
        background:none;
    
        .card__title {
            font-size:1.5rem;
            font-family:$font-primary-light;
            line-height: 1.3;
        }
    
        .card__summary {
            color:$color-primary-800;
        }
    
        .card__body {
            height:100%;
            border-radius: 50px 0 50px 0;
            padding:32px;
            box-shadow: 4px 4px 15px rgba(0,0,0,.1);
            background:$color-white;
            color: $color-primary-700;
            text-align: center;
            display:flex;
            flex-direction: column;
            align-items: center;
            gap:24px;
    
            .card__action {
                margin-top:auto;
                align-self:flex-end;
                 .button {
                    color: $color-neutral-700;
                }
            }
        }
        .card__title {
            text-align: center;
        }
    }
}

.card-icon-list {
    display: flex;
    flex-direction: column;
    gap:20px;
    
    .card.card--icon {
        padding:0;

        &:after {
            @include pseudo(block, static, '');
            width:1px;
            height:50px;
            background:#707070;
        }

        &:last-child:after {
            display: none;
        }
    }
}

@media(min-width:$bp-l){
    .card.card--icon {
        flex-direction: row;
        align-items: flex-start;
        gap:60px;

        .card__media {
            flex-shrink:0;
        }

        .card__content {
            text-align: left;
        }
    }

    .card-icon-list {
        gap:0;

        .card.card--icon {
            overflow: visible;
    
            &:after {
                display: none;
            }
    
            .card__media {
                position:relative;
                padding-bottom:102px;
                max-width:110px;
                
                &:after {
                    @include pseudo();
                    bottom:15px;
                    left:50%;
                    width:1px;
                    height:72px;
                    background:#707070;
                }
            }

            &:last-child .card__media {
                padding-bottom:0;

                &:after {
                    display: none;
                }
            }
        }
    }
    .card.card--icon.swiper-slide {
        padding:0 32px 15px;
    }
}