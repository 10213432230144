@use "../../abstracts/" as *;

%summary-box {
  position: relative;
  font-family: $font-primary-light;
  border-radius: 0 0 0 50px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  width: 100%;
  background: $color-white;
  max-width: 36rem;
  padding:40px 40px 40px 50px;
  box-sizing: border-box;
  font-size: #{"clamp(1.125rem, 3vw, 1.75rem)"};
  line-height: 1.5;
  color: $color-neutral-900;
  opacity: .94;

  &::before {
    content: "";
    width: 80px;
    border-bottom: solid 2px #9EBA27;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: -40px;
    top: 5rem;
    z-index: 1;
  }

  @media(min-width:$bp-l){
    padding:3vw 5vw 5vw;
  }

  @media(min-width:$bp-xxl){
    padding:55px 87px 87px;
  }
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7.8125rem 0 3.5rem;
  background: $color-primary;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  font-family: $font-primary-light;
  background-position: center;

  &.featured {
    padding: 3.5rem 0;

    /*  background-size:cover;
       -webkit-animation: slidein 100s;
       animation: slidein 100s;

       -webkit-animation-fill-mode: forwards;
       animation-fill-mode: forwards;

       -webkit-animation-iteration-count: infinite;
       animation-iteration-count: infinite;

       -webkit-animation-direction: alternate;
       animation-direction: alternate;     */
  }
}

.hero.hero--has-image {
  position: relative;

  &:before {
    @include pseudo();
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(6, 12, 70, 0) 0%, rgba(6, 12, 70, 0.8) 100%);
    z-index: 0;
  }
}

.hero__body {
  position: relative;
  z-index: 1;
  max-width: 43.75em;
}

.hero__title {
  font-family: $font-primary-thin;
  font-weight: 400;
  letter-spacing: 0.004em;
  text-align: left;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.hero__content {
  position: relative;
  font-family: $font-primary-thin;
  font-weight: 300;
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 1.2rem;
}

@-webkit-keyframes slidein {
  from {
    background-position: top;
    background-size: 2000px;
  }

  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 2000px;
  }

  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

.hero-inner {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.hero-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.hero-stack {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 37%;
}

.hero-textbox {
  @extend %summary-box;
}

.hero__link {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: $bp-l) {
  .hero {
    padding: 16rem 0 10rem;
    background-position: top;

    &.featured {
      padding: 6rem 0 9rem;
    }
  }

  .hero-inner {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3rem 0;
  }

  .hero__title {
    font-size: 5rem;
    line-height: 5.4rem;
    letter-spacing: 0.004em;
    font-weight:400;
  }

  .hero__content {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
