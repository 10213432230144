@use '../../abstracts/' as *;

.search-facets-toggle {
    @extend %buttonreset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: $font-primary-bold;
    font-size: .75rem;
    padding: 10px;
    line-height: 2;
    text-transform: uppercase;

    &:after {
        @include pseudo(inline-block, static, '');
        border: solid;
        border-width: 0 1px 1px 0;
        margin-bottom: 3px;
        padding: 3px;
        transform: rotate(45deg);
    }
}

.search-facets {
    position:absolute;
    top:100%;
    left:0;
    z-index:2;
    width:100%;
    display: none;
    max-height:320px;
    padding:16px;
    overflow-y: auto;
    box-shadow: 4px 4px 15px rgba(0,0,0,.1);
    background:$color-white;
}

.search-facets__facet-list {
    @extend %listreset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 1rem;

    &:empty {
        display:none;

        & + div {
            display:none;
        }
    }
}

.search-facets__applied-facets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;

    div {
        order:-1;
    }
}

.search-facets__applied-facets-list:not(:has(li)) {
    display: none;

    & + div {
        display:none;
    }
}

.search-facets__applied-facets-list {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;

    li {
        position:relative;
        display: inline-flex;
        gap:10px;
        align-items: center;
        background:$color-primary-700;
        border-radius:50px;
        padding:0 0 0 20px;
        line-height:normal;
        color:$color-white;

        br {
            display: none;
        }

        span {
            display: block;
            padding:5px;
            font-size:0.875rem;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            background:$color-secondary-600;
            color:$color-white;
            cursor: pointer;
        }
    }
}

.search-facets__applied-facets-clear {
    @extend %buttonreset;
    padding:30px;
	border:1px solid $color-primary-700;
    border-radius:50px;
    padding:16px 30px;
    background-color:transparent;
    font-family: $font-primary-medium;
    font-size:0.75rem;
    font-weight:$fontWeight-normal;
    text-transform: uppercase;
    word-wrap: break-word;
    box-shadow:0 0 0 0 transparent;
    text-decoration: none;
    text-align: center;
    letter-spacing: 1.2px;
    line-height: normal;
    color:$color-primary-800;
    will-change:box-shadow, background-color, color;
    transition: box-shadow .3s ease-in-out,
        background-color .3s ease-in-out,
        color .3s ease-in-out;

    &:hover, &:focus-visible {
        box-shadow: 0px 4px 10px rgba(153.19, 159.06, 167.88, 0.40);
        background:$color-primary-700;
        color:$color-white;
    }

    &:active {
        background-color: $color-primary-800;
    }
}

.search-facets__facet-show {
    @extend %buttonreset;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-family: $font-primary-bold;
    font-size: .75rem;
    padding: 10px;
    line-height: 2;
    text-transform: uppercase;

    &:after {
        @include pseudo(inline-block, static, '');
        border: solid;
        border-width: 0 1px 1px 0;
        margin-bottom: 3px;
        padding: 3px;
        transform: rotate(45deg);
    }
}

@media(min-width:$bp-m){
    .search-facets-wrap {
        margin-top:180px;
    }
    
    .search-facets-toggle {
        display: none;
    }

    .search-facets {
        position:static;
        display: block !important;
        overflow: visible;
        max-height:none;
        padding:0;
        box-shadow: none;
    }
}