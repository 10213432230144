@use '../../abstracts/' as *;

.card-carousel {
    position:relative;
    overflow: hidden;

    .swiper-slide {
        width:386px;
        height: auto;
    }
}

.card-carousel__nav {
    --swiper-pagination-bullet-inactive-opacity: .3;
    --swiper-pagination-bullet-border-radius:0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap:40px;
    margin-top:60px;

    .card-carousel__pager {
         position: static;
         display: flex;
         flex-wrap: wrap;
         gap:17px;
         width:auto;

         .swiper-pagination-bullet {
            width:47px;
            height:2px;
            background:#838A95;
            font-size:0;
            opacity: 1;
            color:transparent;

            &.swiper-pagination-bullet-active {
                background:$color-primary-500;
            }
         }
    }

    .card-carousel__buttons {
        display:none;
        align-items: center;
        gap:30px;
    }

    .card-carousel__button {
        @extend %buttonreset;
        position:static;
        width:auto;
        height:auto;
        padding:0;

        &:after {
            display: none;
        }

        .card-carousel__button-icon {
            width:17px;
            height:17px;
            color:#5A5D80;
        }
    }
}

@media(min-width:$bp-l){
    .card-carousel__nav {
        .card-carousel__pager {
            gap:32px;
            
            .swiper-pagination-bullet {
                width:118px;
            } 
        } 

        .card-carousel__buttons {
            display: flex;
        }
    } 
}