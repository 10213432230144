@use '../../abstracts/' as *;

.card--dynamic-icon-media{
    display: none;
}

.card.card--dynamic-icon {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    font-family: $font-primary-light;
    font-size:#{"clamp(1.125rem, 3vw, 1.5rem)"};
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0.012em;
    color: $color-primary-800;
    background: none;
    overflow: visible;
    &:after {
        border-left: 2px solid #707070;
        content: "";
        display: block;
        height: 100px;
        position: absolute;
        width: 1px;
        bottom: -80px;
        left: 50%;
    }
    &:last-child{
        &:after {
            display: none;
        }
    }
    strong{
        color: $color-primary-600;
    }

    .card__title {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.004em;
        color:$color-primary-700;
        font-weight: 700;
    }

    .card__inner {
        display: flex;
        align-items: flex-start;
        gap:2rem;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        padding: 2.5rem 2rem;
        margin-bottom: 100px;
        position: relative;

    }
    .card__content {
        &>p{
            margin-bottom: 0;
        }
    }
    .card__media-wrapper{
        width: 100%;
    }
    .card__media{
        display: flex;
    flex-grow: 1;
    width: 150px;
    height: 140px;
    background: no-repeat center/contain;
    margin: 0 auto;
    }

    .card__summary{
        display: none;
        margin-top: 1rem;
    }
}

@media(min-width:$bp-m){
    .card--dynamic-icon-media{
        display: block;
        .card__media-wrapper{
            display: block;
        }
        .card__media__sticky{
        width: 150px;
        height: 140px;
        background: no-repeat center/contain;
        margin: 0 auto;
    }
    .grid-layout__sticky{
        top:220px;
    }
    }

    .card.card--dynamic-icon {
            font-size:#{"clamp(1.5rem, 3vw, 1.5rem)"};
            font-family: $font-primary;
            font-weight: 300;
            line-height: 34px;
            letter-spacing: 0.24px;
            &:after {
                border-left: 2px solid #707070;
                bottom: -75px;
                content: "";
                display: block;
                height: 100px;
                left: 0px;
                position: absolute;
                width: 1px;
            }
            .card__media-wrapper{
                display: none;
            }
        .card__inner{
            gap:3.5rem;
            padding: 0 3rem 3rem;
            flex-direction: row;
        }

        .card__body{
            text-align: left;
        }
        .card__summary {
            display: block;
            margin-top: 1.5rem;
        }
    }
}
