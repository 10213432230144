@use '../../abstracts/' as *;

.hero.hero--featured {
    padding:100px 30px 95px 20px;

    .hero__body {
        display: flex;
        flex-direction: column;
        gap:40px;
        max-width:none;
    }

    .hero__title {
        margin:0;
        font-size: #{"clamp(3.25rem, 4vw, 5.625rem)"};
        line-height:1.0769;
        text-shadow: 0px 0px 10px rgba(6, 12, 70, 0.30);
    }

    .hero__content {
        position:relative;
        margin:0 0 0 25px;
        padding:40px 40px 80px 50px;
        background:rgba(255, 255, 255, .9);

        &:before {
            @include pseudo();
            top:56px;
            left:-25px;
            height:2px;
            width:50px;
            background:$color-secondary-400;
        }
    }

    .hero__summary {
        font-family: $font-primary-light;
        font-size: #{"clamp(1.125rem, 3vw, 1.75rem)"};
        line-height:1.5;
        color:$color-primary-800;
    }

    .button {
        color:$color-neutral-700;
    }
}

@media(min-width:$bp-m){
    .hero.hero--featured {
        padding:100px 30px 185px 20px;
    }
}

@media(min-width:$bp-l){
    .hero.hero--featured {
        padding: 7.4vw 20px 12vw;

        .hero__inner {
            display: flex;
            justify-content: center;
        }

        .hero__body {
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            gap:13vw;
        }

        .hero__title {
            align-self: flex-start;
            margin-top:2vw;
            text-shadow: 0px 0px 12.5px rgba(0, 0, 0, 0.43);
        }

        .hero__content {
            max-width:611px;
            margin-left:0;
            padding:3vw 5vw 5vw;

            &:before {
                top:3.5vw;
                left:-5vw;
                width:105px;
                background:#9EBA27;
            }
        }
    }
}

@media(min-width:$bp-xxxl){
    .hero.hero--featured {
        padding:142px 20px 230px;

        .hero__body {
            gap:250px;
        }

        .hero__title {
            margin-top:55px;
        }

        .hero__content {
            padding:55px 87px 87px;

            &:before {
                top:70px;
                left:-50px;
            }
        }
    }
}