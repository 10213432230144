@use '../../abstracts/' as *;

.news-detail__header {
    background:$color-neutral-300;

    .container {
        max-width: 1182px;
    }
}

.news-detail__img {
    display: block;
    margin:0 auto;
}

.news-detail__topic {
     font-size:0.875rem;
}

.news-detail__meta {
    display: flex;
    gap:10px;

    .news-detail__share {
        margin-left:auto;
    }
}

.news-detail__share {
    @extend %listreset;
    display: flex;
    gap:10px;
    align-items: center;

    svg {
        width:16px;
        height:16px;
    }
}

.news-detail__content {
    max-width: 1182px;
    margin-left: auto;
    margin-right:auto;
}