@use '../../abstracts/' as *;

.card.card--blue-overlap {
    padding:0;
    
    .card__title-wrap {
        position: relative;
        left: 0;
        top: -10%;
        width: 90%;
        padding: 3rem 3.5rem;
        color: $color-white;
        background: linear-gradient(109.86deg, #1662CC 20.25%, #3492EA 87.38%);
        border-bottom-right-radius: 50px;
    }

    .card__body-inner {
        padding: 0 2rem;
    }

    hr {
        display: none;
    }
}

@media(min-width:$bp-m){
    .card__BlueOverlap{
        padding:44px 4.5% 0;

        .card__title-wrap {
            position: absolute;
            left: auto;
            right: -6%;
            top: 20%;
            width: 64%;
            z-index: 3;
            background: linear-gradient(109.86deg, #1662CC 20.25%, #3492EA 87.38%);
            border-bottom-right-radius: 0;
            border-top-right-radius: 50px;
        }

        .card__media {
            margin-left: 0;

            &:before {
                @include pseudo();
                right: -39px;
                top: -44px;
                z-index: 1;
                height: 114px;
                width: 108px;
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 30px 30px;
            }
        }

        .card__img {
            position: relative;
            z-index: 2;
        }
        
        .card__body {
            margin-left: 0;
            padding: 0 4% 0 6%;
        }

        
        .card__body-inner {
            padding-left: 5%;
            padding-right: 0;
        }

        hr{
            display: block;
            border-top: 2px solid #3B8EDE;
            width: 10%;
        }
    }
}