@use '../../abstracts/' as *;

.staff-slider {
    position:relative;
}

.staff-slider__pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
    opacity: 1;
    position: static;   
  
    .swiper-pagination-bullet {
        height: 2px;
        border-radius: 0;
        margin: 0;
        flex-grow:1;
        opacity: 1;
        background: #A2AAAD;
        font-size: 0;
        color: transparent;
  
      &.swiper-pagination-bullet-active {
        background:$color-primary-500;
      }
    }
}

@media(min-width:$bp-m){
    .staff-slider__pagination {
        display: none;
    }
}