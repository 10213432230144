@use '../../abstracts/' as *;

.lang-selector {
    position:relative;
}

.lang-selector__toggle {
    @extend %buttonreset;
    display: inline-flex;
    align-items: center;
    gap:10px;
    padding:10px;
    height: 80px;
    font-family: $font-primary-bold;
    font-size:0.75rem;
    text-transform: uppercase;
    line-height:2;
    white-space: nowrap;
    color:$color-primary-800;

    &:after {
        content:'';
        display: inline-block;
        border: solid currentColor;
        border-width: 0 1px 1px 0;
        margin-bottom:3px;
        padding: 3px;
        transform: rotate(45deg);
    }
}

.lang-selector__menu {
    display: none;
    position: absolute;
    top:100%;
    left:0;
    z-index:10;
    border-radius: 0 0 5px 5px;
    min-width:100%;
    width:auto;
    white-space: nowrap;
    background:rgba(242, 242, 242, 0.80);
}

.lang-selector__list {
    @extend %listreset;
    display: flex;
    flex-direction: column;
}

.lang-selector__item {
    border-bottom:1px solid $color-neutral-500;
    padding:8px 0;
    text-align: center;

    &:last-child {
        border-bottom:0;
    }
}

.lang-selector__link {
    font-family: $font-primary-bold;
    font-size:0.75rem;
    text-decoration: none;
    text-transform: uppercase;
    color:$color-neutral-700;
}