@use '../abstracts' as *;

%button {
    display:inline-block;
	padding:30px;
	border:1.5px solid transparent;
    border-radius:0px 30px;
    padding:16px 30px;
    background-color:transparent;
    font-family: $font-primary-thin;
    font-size:0.8rem;
    font-weight:$fontWeight-semibold;
    text-transform: uppercase;
    word-wrap: break-word;
    box-shadow:0 0 0 0 transparent;
    text-decoration: none;
    text-align: center;
    letter-spacing: 3.2px;
    line-height: normal;
    will-change:box-shadow, background-color, color;
    transition: box-shadow .3s ease-in-out,
        background-color .3s ease-in-out,
        color .3s ease-in-out;

    &:hover, &:focus {
        text-decoration: none;
    }

    &:focus-visible {
        text-decoration: underline;
    }
}

.button {
    @extend %button;

    &.button--primary {
        border-color:$color-secondary-300;
        color:$color-secondary-300;

        &:hover, &:focus-visible {
            box-shadow: 0px 4px 10px rgba(153.19, 159.06, 167.88, 0.40);
            background:$color-secondary-300;
            color:$color-white;
        }
    }

    &.button--primary-light {
        border-color:$color-white;
        color:$color-white;

        &:hover, &:focus-visible {
            box-shadow: 0px 4px 10px rgba(153.19, 159.06, 167.88, 0.40);
            background:$color-white;
            color:$color-primary-700;
        }
    }

    &.button--green {
        border-color:$color-primary-500;
        color:$color-primary-500;

        &:hover, &:focus-visible {
            box-shadow: 0px 4px 10px rgba(153.19, 159.06, 167.88, 0.40);
            background:$color-primary-500;
            color:$color-white;
        }
    }

    &.button--green-light {
        border-color:$color-white;
        color:$color-white;

        &:hover, &:focus-visible {
            box-shadow: 0px 4px 10px rgba(153.19, 159.06, 167.88, 0.40);
            background:$color-white;
            color:$color-primary-500;
        }
    }

    &.button--arrow {
        position: relative;
        display: inline-flex;
        gap:24px;
        align-items: center;
        border:0;
        padding:0;
        font-family: $font-primary-bold;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1.68px;
        color:$color-neutral-600;

        .arrow {
            position:relative;
            width:32px;
            height:32px;
            border-radius: 50%;
            background: #F2F1F0;
            will-change: background-color;
            transition: background-color .3s ease-in-out;
            
            &--green{
                background: rgba(191, 217, 160, 0.5);
                height: 37px;
                width: 37px;
            }
        }

        .shaft {
            position: absolute;
            width: 100%;
            top: 50%;
            z-index: 2;
            display: block;
            height: 1px;
            background-color: currentColor;
            will-change: transform;
            transition: transform 0.2s;
            transform:translateX(0);

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                z-index: 2;
                display: block;
                height: 1px;
                width: 12px;
                background-color: currentColor;
                transition: all 0.2s;
            }
        }

        &:hover, &:focus-visible {
            .arrow {
                background-color:transparent;
            }
        }

        &:active {
            color:$color-primary-600;
        }

        &.button--arrow-right {
            .shaft {
                right: 10px;

                &:before, &:after {
                    right: 0;
                }

                &:before {
                    transform-origin: top right;
                    transform: rotate(40deg);
                }

                &:after {
                    transform-origin: bottom right;
                    transform: rotate(-40deg);
                }
            }

            &:hover, &:focus-visible {
                .shaft {
                    transform:translateX(15%);
                }
            }
        }


        &.button--arrow-left {
            .arrow {
                order:-1;
            }

            .shaft {

                &:before, &:after {
                    left:0;
                }

                &:before {
                    transform-origin: top left;
                    transform: rotate(-40deg);
                }

                &:after {
                    transform-origin: bottom left;
                    transform: rotate(40deg);
                }
            }

            &:hover, &:focus-visible {
                .shaft {
                    transform:translateX(-15%);
                }
            }
        }

        &.button--arrow-down {
            .shaft {
                height: 35px;
                left: 50%;
                top: -10px;
                width: 1px;

                &:before, &:after {
                    right: 1px;
                    top: 26px;
                }

                &:before {
                    transform-origin: bottom right;
                    top: 34px;
                    transform: rotate(40deg);
                }

                &:after {
                    transform-origin: bottom right;
                    transform: rotate(-40deg);
                    right: -9px;
                }
            }

            &:hover, &:focus-visible {
                .shaft {
                    transform:translateX(15%);
                }
            }
        }
    }

    &.button--md {
        @media(min-width:$bp-mw){
            padding:23px 56px;
            font-size:0.9375rem;
            letter-spacing: 1.5px;
        }
    }

    &.button--lg {
        @media(min-width:$bp-xl){
            padding:30px 72px;
            font-size:1.125rem;
            letter-spacing: 1.8px;
        }
    }
}
