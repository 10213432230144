@use '../abstracts' as *;

$inputHeight: 55px !default;
$inputBg: transparent !default;
$inputBorderColor: #A2AAAD !default;
$inputBorderWidth: 1px !default;
$inputBorderRadius: 0 !default;
$inputBoxShadow: inset 0 1px 5px rgba(0, 0, 0, 0.22) !default;
$inputBoxShadowActive: inset 0 1px 5px rgba(0, 0, 0, 0.22), inset 0 0 0 2px $color-primary !default;


/* ================================================================
   Wraps
   ================================================================ */

.form,
form {
    margin: 0.9375rem 0;
}

.form-group,
.input-wrapper {
    margin-bottom: 1rem;
}


/* ================================================================
   Labels/Indicators
   ================================================================ */

.form-control-label {
    display: block;
    font-weight: bold;
}

.required-indicator {
    position: relative;
    padding-left: 10px;

    &:before {
        display: block;
        content: '*';
        position: absolute;
        left: 0;
        top: 0;
        color: red;
    }
}

.error-message {
    margin-top: 5px;
    font-size: 1em;
    color: red;
}





/* ================================================================
   Controls/Inputs
   ================================================================ */

.form-control {
    width: 100%;
    height:$inputHeight;
    padding: 0;
    border:0;
    border-style:solid;
    border-color:$inputBorderColor;
    border-bottom-width:1px;
    background: $inputBg;
    font-family:$font-primary-light;
    font-size: 1rem;
    line-height: 1.7;
    color: $color-black;
    will-change: border-color;
    -webkit-appearance: none;
    transition: border-color $nav-transition-speed ease-in-out;;

	&::placeholder {
		color: $color-primary-800;
	}
    &:focus {
        outline:0;
        border-color: $color-primary;
    }

    @media(min-width:$bp-l){
        font-size:1.25rem;
    }
}

input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="url"],
input[type="time"],
input[type="color"],
input[type="number"],
input[type="search"] { @extend .form-control; }

// for custom checkbox/radio inputs, position the real input out of view
// use focus and sibling selectors to style the pseudo element
.radio-item {
    position:relative;

    .radio-item__label {
        display: inline-flex;
        align-items: flex-start;
        gap:5px;
        cursor: pointer;

        &:before, &:after {
            content:'';
            display: block;
            border-radius: 50%;
        }

        &:before {
            width:16px;
            height:16px;
            border:1px solid $color-neutral-400;
            background-color:$color-neutral-50;
            box-shadow:0 0 0 0 transparent;
            transition:box-shadow .3s ease-in-out;
        }

        &:after {
            position:absolute;
            top:8px;
            left:5px;
            width:6px;
            height:6px;
            opacity:0;
            background-color: $color-primary-700;
            transition:opacity .3s ease-in-out;
        }
    }

    .radio-item__input {
        position:absolute;
        top:4px;
        left:1px;
        width:14px;
        height:14px;
        opacity: 0;

        &:active, &:focus-visible {
            + .radio-item__label:before {
                box-shadow: 0 0 0 0.2rem $color-tertiary-4;
            }
        }

        &:checked + .radio-item__label {
            &:before {
                border-color:$color-primary-700;
            }

            &:after {
                opacity:1;
            }
        }
    }
}

.checkbox-item {
    position:relative;

    .checkbox-item__label {
        display: inline-flex;
        align-items: flex-start;
        gap:5px;
        cursor: pointer;

        &:before, &:after {
            content:'';
            display: block;
        }

        &:before {
            width:16px;
            height:16px;
            border:1px solid $color-neutral-600;
            border-radius:4px;
            margin-top:3px;
            background-color:$color-neutral-50;
            box-shadow:0 0 0 0 transparent;
            transition:box-shadow .3s ease-in-out;
        }

        &:after {
            position:absolute;
            top:5px;
            left:1px;
            width:14px;
            height:14px;
            opacity:0;
            background:
                url("../images/check.svg")
                no-repeat 
                center center / 100% auto;
            transition:opacity .3s ease-in-out;
        }
    }

    .checkbox-item__input {
        position:absolute;
        top:0;
        left:1px;
        width:14px;
        height:16px;
        opacity: 0;

        &:active, &:focus-visible {
            + .checkbox-item__label:before {
                box-shadow: 0 0 0 0.2rem $color-primary-300;
            }
        }

        &:checked + .checkbox-item__label {
            &:before {
                border-color:$color-primary-700;
            }

            &:after {
                opacity:1;
            }
        }
    }
}

select {
    @extend .form-control;
    border-width:0 0 1px;
    padding-right:30px;
    padding-left: 20px;
    background-image:url($img-path+"select-arrow.png");
    background-position-x: 100%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-repeat: no-repeat;

    &.bordered {
        border:1px solid $inputBorderColor;
        padding-left:40px;
        padding-right:40px;
        background-position-x: calc(100% - 10px);
    }

    &.form-control {
        padding-left:0;
    }
}

textarea,
textarea.form-control {
    @extend .form-control;
	padding: 1rem 0;
	min-height: 100px;
}

button[type="submit"],
input[type="submit"] {
    border:1px solid $color-primary-800;
    border-radius:33px;
    padding:23px 56px;
    background:transparent;
    text-align: center;
    text-transform: uppercase;
    font-family:$font-primary-medium;
    font-size:0.9375rem;
    letter-spacing: 1.5px;
    color:$color-primary-800;
    cursor: pointer;
    will-change: background, color;
    transition: background .3s ease-in-out, color .3s ease-in-out;

    &:hover, &:focus {
        background:$color-primary-800;
        color:$color-white;
    }
}

.sf-FileField .sf-icon-remove {
    display: inline-block;
    cursor: pointer;
}

input[type=file].sf-FileField-input {
    display: inline-block;
}

[data-sf-role="error-message"] {
    color:$color-error-2;
}

.hinted-input {
    position:relative;

    label {
        position:absolute;
        top:0;
        left:0;
        opacity:0;
        will-change:opacity;
        transition:opacity .3s ease-in-out;
    }

    input, .form-control {
        padding-top:20px;
        &:not(:placeholder-shown) + label {
            opacity:1;
        }
    }
}