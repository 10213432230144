@use '../../abstracts/' as *;

.card {
    position:relative;
    border-radius:10px;
    padding:1.875rem 1.875rem 3rem 1.875rem;
    background:$color-white;
    overflow: hidden;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);

    .button {
        color:currentColor;

        // &:hover, &:focus-visible {
        //     color:currentColor;
        // }
    }
}

.card__header {
    display: flex;
    gap:1.25rem;
    align-items: center;
    flex-direction: column;
}

.card__title {
    @extend %item__title;
}

.card__date {
    @include x-rem(font-size, 18px);
}