@use '../../abstracts/' as *;

.news-item.swiper-slide {
    height:auto;
}

.news-slider__nav {
    --swiper-pagination-bullet-width: 60px;
    --swiper-pagination-bullet-height: 2px;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: #838A95;
    --swiper-pagination-color: #{$color-primary-500};
    --swiper-pagination-bullet-border-radius:0;
    --swiper-navigation-size:17px;

    display: flex;
    gap:30px;
    align-items: center;
    justify-content: center;
    margin-top:45px;

    .news-slider__buttons {
        flex-shrink: 0;
    }
}

.news-slider__pager {
    position:static;
    display: flex;
    flex-wrap:wrap;
    gap:20px;
    width:auto !important;

    .swiper-pagination-bullet {
        font-size:0;
        color:transparent;
        transition:background .3s ease-in-out;
    }
}

.news-slider__buttons {
    display: flex;
    gap:30px;
}

.news-slider__button {
    @extend %buttonreset;
    position:static;
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    margin-top:0;
    color:$color-primary-700;

    &:after {
        display: none;
    }
}

.news-slider.news-slider--cards {
    --slider-offset:24px;
    padding-left:var(--slider-offset);
    padding-right:var(--slider-offset);

    .swiper-wrapper {
        align-items: flex-end;
    }
    
    .swiper-slide {
        width:238px;
    }

    .news-slider__nav {
        margin-top:40px;
        margin-left:calc(var(--slider-offset) * -1);
        margin-right:calc(var(--slider-offset) * -1);

        .news-slider__pager {
            gap:5px;
        }
    }
}

@media(min-width:$bp-m){
    .news-slider.news-slider--cards {
        .swiper-slide {
            width:48%;
        }
    }
}

@media(min-width:$bp-l){
    .featured-news-section .news-slider-wrap {
        --news-slider-offset:140px;
        position:relative;
        margin-top:#{calc(var(--news-slider-offset) * -1)};
        margin-bottom:var(--news-slider-offset);
        bottom:#{calc(var(--news-slider-offset) * -1)};
    }

    .news-slider__nav {
        --swiper-pagination-bullet-width: 120px;
    }

    .news-slider__button {
        color:#091070;
    }

    .news-slider.news-slider--cards {
        --slider-offset:0;

        .swiper-slide {
            width:24%;
        }

        .news-slider__nav {
            .news-slider__pager {
                gap:30px;
            }
        }
    }
}