@use '../../abstracts/' as *;

.card.card--image-right-blue {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    background:none;
    color: unset;

    &:after {
        display:none;
    }

    .card__title {
        padding:56px 32px;
        font-size:#{"clamp(2.75rem, 5vw, 5rem)"};
        line-height: 1.2;
    }
    
    .card__body {
        padding: 56px 40px;
        background:linear-gradient(90deg,#000f9f,#0c2aac 9.85%,#3b8ede);
        color:$color-white;
    }

    .card__img {
        width:100%;
    }

    .card__subheading {
        font-size:1.875rem;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
}

@media(min-width:$bp-mw){
    .card.card--image-right-blue {
        display: grid;
        grid-template-columns: 64.3% auto;
        justify-content: end;
        row-gap: 1.5rem;
        column-gap:32%;

        .card__title {
            grid-column-start: 1;
            grid-row-start: 1;
            padding:0;
            line-height: 1.1;
            max-width:90%;
            margin-bottom: 2rem;
        }

        .card__media {
            position: absolute;
            bottom: 13%;
            right: 0;
            width: 36.8%;
            height: 100%;
            max-height:625px;
            overflow: hidden;
        }

        .card__pic, 
        .card__img  {
            position: absolute!important;
            top: 0;
            right: 0;
            height:100%;
        }

        .card__pic {
            width:100%;
        }

        .card__img {
            width:auto;
            min-width: 100%;
            max-width: none;
            object-fit: cover;
        }

        .card__body {
            position:relative;
            grid-column: 1/2;
            grid-row-start: 2;
            text-align: left;
            padding: 7.4% 12% 9.4% 14.3%;
            background:none;

            &:before {
                @include pseudo();
                top:0;
                left:0;
                width:100%;
                height:100%;
                border-bottom-right-radius: 50px;
                background:linear-gradient(90deg,#000f9f,#0c2aac 9.85%,#3b8ede);
                opacity: .9;
            }

            &:after {
                @include pseudo();
                z-index: 1;
                left: -40px;
                top: 5rem;
                width: 80px;
                border-bottom: 2px solid #9EBA27;
            }
        }

        .card__body-inner {
            position:relative;
        }

        .card__subheading {
            font-size:1.75rem;
            line-height:1.5;
        }

        .button {
            padding-left:56px;
            padding-right:56px;
        }
    }
}

@media(min-width:$bp-xxxl){
    .card.card--image-right-blue {
        grid-template-columns: 49.3% auto;
    }
}