@use '../../abstracts/' as *;

.products-table {
    display:none;
    thead > tr > th:first-child {
        width:32.49%;
    }
}

.products-table__item-title {
    font-size:1.625rem;
}

.products-table__item-desc {
    margin-top:28px;
    font-size:1rem;
}

.products-table__item-apps {
    display: flex;
    flex-direction: column;
    gap:16px;
    margin:0;
    padding:0;
    list-style-type: none;

    li {
        &:before {
            @include pseudo(inline-block, static, '');
            vertical-align: top;
            width:22px;
            height:22px;
            margin-right:18px;
            background-image:url($img-path+"checkmark-circle.png");
        }   
    }
}

.products-table__item-meta {
    display: grid;
    gap:40px;
    
    .button {
        justify-self:end;
    }
}

.products-accordion {
    .accordion-item__toggle {
        font-family: $font-primary-bold;
    }
}

.product-accordion__subhead {
    color:#5A5D80;
}

@media(min-width:$bp-l){
    .products-table {
        display: table;
    }

    .products-accordion {
        display: none;
    }

    .products-table__item-meta {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap:30px;
        align-items: center;
    }

    .products-table__item-apps {
        flex-direction: row;
        gap:30px;
        color:$color-primary-500;

        li:before {
            width:20px;
            height:20px;
            margin-right:10px;
            background-image:url($img-path+"check.png");
        }
    }
}