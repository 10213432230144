@use '../../abstracts/' as *;

.careers-header {
    display: grid;
}

.careers-header__col {
    position:relative;
}

.careers-header__col.careers-header__col--content {
    display: flex;
    flex-direction: column;
    gap:60px;
    align-items: center;
    text-align: center;
}

.careers-header__body {
    display: flex;
    flex-direction: column;
    gap:20px;
    padding:0 24px;

    .careers-header__action {
        margin-top:12px;
    }
}

.careers-header__img {
    width:100%;
}

.careers-header__content {
    font-family: $font-primary-light;
    font-size:#{"clamp(1.125rem, 3vw, 1.75rem)"};
    line-height: 1.5;
}

.careers-header__item {
    position: relative;
    display: none;
}

.careers-header__item-inner {
    position: relative;
    overflow: hidden;

    .careers-header__item-img {
        position:absolute;
        top:0;
        left:0;
        min-width:100%;
        height:100%;
        object-fit: cover;
    }
}

@media(min-width:$bp-l){
    .careers-header {
        align-items: start;
        grid-template-columns: 1fr 40% 30%;
        max-width: 1728px;
        margin-left: auto;
        margin-right: auto;
        padding-top:45px;
        padding-left:45px;

        .careers-header__col.careers-header__col--content {
            align-self:stretch;
        }
    }

    .careers-header__body {
        position:sticky;
        top:80px;
        gap:32px;
        max-width:565px;
        padding:0;

        .careers-header__action {
            margin-top:0;
        }
    }

    .careers-header__item {
        display: block;
    }

    .careers-header__col.careers-header__col--content {
        padding-top:74%;
        padding-bottom:93%;

        .careers-header__img {
            position: absolute;
            top:0;
            right:0;
            width:52.6%;
        }
    }

    .careers-header__col.careers-header__col--images-1 {
        margin-top:-7%;
        padding-right:13%;
        padding-bottom:76%;

        .careers-header__item {
            &:first-child {
                z-index:2;
                margin-bottom:-11.7%;
                
                .careers-header__item-inner {
                    width:88%;
                    padding-top:62%;
                    margin-left:auto;
                    margin-right: -36%;
                }
            }
    
            &:nth-child(2) {
                position:relative;
    
                &:before {
                    @include pseudo(block, static, '');
                    width:56.5%;
                    padding-top:89%;
                    border-bottom-left-radius: 50px;
                    background: linear-gradient(315deg, #1662CC 20.85%, #3492EA 70.69%);
                }
    
                .careers-header__item-inner {
                    position: absolute;
                    top:16%;
                    left:15.1%;
                    z-index:1;
                    width:51.5%;
                    height:74%;
                }
            }
    
            &:nth-child(3) {
                margin-top:15.4%;

                .careers-header__item-inner {
                    width:68.4%;
                    padding-top:88%;
                    margin-left:auto;
                }
            }
    
            &:nth-child(4)  {
                z-index: 2;
                margin-top:-20px;

                .careers-header__item-inner{
                    width:64.4%;
                    padding-top:52.2%;
                }

                &:after {
                    @include pseudo();
                    bottom:-21%;
                    left:56.3%;
                    width:8.1%;
                    height:53%;
                    background:url('/dist/images/line-accent.png') no-repeat 0 0;
                    background-size:100% auto;
                }
            }
    
            &:nth-child(5) {
                position: absolute;
                bottom:0;
                right:-31.4%;
                left: 0;
                .careers-header__item-inner {
                    width:64.7%;
                    margin-left:auto;
                    padding-top:41.4%;
                }
            }
        }
    }

    .careers-header__col.careers-header__col--images-2 {
        padding-bottom: 40%;
        padding-left: 12%;
        padding-top: 26.5%;

        .careers-header__item {
            &:first-child {
                margin-left:-11%;

                .careers-header__item-inner {
                    width:80%;
                    padding-top:69.5%;
                }

                &:after {
                    @include pseudo();
                    top: -13%;
                    left: 64%;                
                    width:23%;
                    height:35%;
                    background-image: radial-gradient(#091070 5%,transparent 7%);
                    background-position: 0 0;
                    background-size: 28% 28%;
                }
            }
    
            &:nth-child(2) {
                margin-top:42%;

                .careers-header__item-inner {
                    width:75%;
                    padding-top:91%;
                }
            }
    
            &:nth-child(3) {
                position:relative;
                z-index: 1;
                margin-top:38%;
                padding-bottom:12%;

                &:before {
                    @include pseudo(block, static, '');
                    width:94%;
                    padding-top:80.5%;
                    margin-left:auto;
                    border-top-left-radius: 50px;
                    background:linear-gradient(324deg, #A8BF15 4.88%, #658D30 79.06%);
                }

                .careers-header__item-inner {
                    position: absolute;
                    top:28.5%;
                    width:92%;
                    padding-top:66%;
                }
            }
    
            &:nth-child(4) {
                position:absolute;
                bottom:0;
                left:0;
                right: 0;
                height:13%;
                z-index: 1;
                
                .careers-header__item-inner {
                    width:72%;
                    height:100%;
                }
            }
        }
    }
}