@use '../abstracts' as *;

h1, .h1, 
h2, .h2, 
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin:0;
	font-family:$font-heading;
	font-weight:normal;
}

h1, .h1, 
h2, .h2, {
	line-height:1.1;
}

h1, .h1 {
	font-size:#{"clamp(3rem, 4.5vw, 5.625rem)"};
}
h2, .h2 {
	font-size:#{"clamp(2.75rem, 3.2vw, 5rem)"};
	font-family: $font-primary-thin;
}
h3, .h3 {
	font-size:#{"clamp(2.2rem, 2.3vw, 3.375rem)"};
	font-family: 'Space Grotesk', sans-serif;
	line-height: 1.2;
}
h4, .h4 {
	font-size:#{"clamp(1.5rem, 1vw, 2.5rem)"};
	line-height: 1.6;
	font-family: 'Mulish', sans-serif;
}
h5, .h5 {
	font-size:#{"clamp(1.15rem, 1.35vw, 2.25rem)"};
	line-height:1.6;
	font-family: 'Mulish', sans-serif;
}
h6, .h6 {
	font-size:#{"clamp(0.875rem, 0.875vw, 38px)"};
	line-height:1.25;
	font-family: 'Space Grotesk', sans-serif;
	text-transform: uppercase;
	letter-spacing: 5px;
	font-weight: 800;
}

.h7 {
	font-size:#{"clamp(1rem, 1.3vw, 1.5rem)"};
	line-height:1.6;
}

.h8 {
	font-size:#{"clamp(0.75rem, .8vw, 0.875rem)"};
	line-height:1.6;
	text-transform: uppercase;
	letter-spacing: 1.68px;
}

.h9 {
	font-size:0.75rem;
	line-height:1.6;
	text-transform: uppercase;
	letter-spacing: .6px;
	
}

.subhead {
	font-size:#{"clamp(1.125rem, 1.6vw, 28px)"};
	line-height:1.5;
}

.eyebrow {
	font-size: 0.85rem;
	font-family: 'Space Grotesk', sans-serif;
	text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 600;
}

.heading-underline {
	position:relative;
	padding-bottom:40px;

	&:after {
		@include pseudo();
		bottom:20px;
		left:0;
		width:50px;
		height:2px;
		background-color:#775EE6;
	}

	@media(min-width:$bp-l){
		padding-bottom:80px;

		&:after {
			bottom:40px;
		}
	}
}

p {
    margin:0 0 $typography-margin-bottom;
	font-size:#{"clamp(1rem, 3vw, 1.25rem)"};
	letter-spacing: 0px;
	line-height:1.7;
	font-weight:300;
}

p.large {
	font-size:1.275rem;
	line-height: 1.6;
}

p.small {
	font-size:1rem;
	line-height:1.62;
}

a {
	color: #775EE6;
	font-family: $font-primary-bold;
	text-decoration: none;
	&:hover, &:focus { text-decoration:underline; }
}

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}