@use '../abstracts' as *;

:root {
    @each $color, $colorVal in $colors {
        --color-#{$color}:#{$colorVal};
    }

    --sf-backgrоund-image: transparent;
    --sf-backgrоund-image: none;
    --sf-background-size: auto;
    --sf-background-position: 0% 0%;
}


html,
button,
input,
select,
textarea {
    color: $color-base;
}

body {
    position: relative;
    max-width: 1920px;
    margin:0 auto;
    font-family: 'Mulish', sans-serif;
    line-height: 1.4;
    overflow-y: scroll;
    background: $body-bg
}

::-moz-selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid #ccc;
}

img {
    vertical-align: middle;
    width: auto;
    height: auto;
    max-width: 100%;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden,
.visually-hidden,
.visuallyHidden,
.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus,
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
}

.invisible {
    visibility: hidden;
}

figure {
    margin: 1em 0;
}

code {
    border-radius: 3px;
    padding:3px;
    background:#ccc;
    color:#333;
}

iframe {
    border:0 !important;
    max-width:100%;
}

/* ================================================================
   Talon Util Boilerplate Additions
================================================================ */

*:focus:not(:focus-visible) {
    outline:0;
}

svg.icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.skip-link {
    @extend .visually-hidden;
    text-align: center;

    &:focus {
        position: static;
        display: block;
        color: $color-invert;
        background: $color-primary;
        padding: 20px 0;
        height: auto;
        width: 100%;
    }
}

.table-wrap {
    overflow-x: auto;

    table {
        width:auto;
        max-width: none;
        min-width:100%;
    }
}

.lazy-img, .lazy-bg {
    opacity: 0;
    transition:opacity $nav-transition-speed ease-in-out;

    &.inView { opacity:1; }

    .no-js & { display:none; }
}