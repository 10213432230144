@use '../../abstracts/' as *;

.staff.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height:auto;
}

.staff__media {
    position:relative;
    overflow: hidden;
    padding-top:100%;

    .staff__img {
        position: absolute;
        top:0;
        max-width:none;
        min-width: 100%;
        height:100%;
        object-fit: cover;
    }
}

.staff__img {
    width:100%;
}

.staff__name {
    margin-top:16px;
    font-size:2.25rem;
    color:$color-primary-700;
}

.staff__position {
    font-size:0.8125rem;
    letter-spacing: 1px;
}

.staff__meta {
    display: flex;
    align-items: center;
    gap:10px;
    margin-top:8px;

    .icon {
        margin-left:auto;
    }
}

.staff__toggle {
    @extend %buttonreset;
    display: block;
    width:100%;
    text-align: left;

    .icon {
        width:30px;
        height:30px;
        color:$color-white;
    }
}

.staff__bio[open] {
    max-width: none;
    max-height:none;
    width:90%;
    max-width:1112px;
    height: 100%;
    border:0;
    margin:0 0 0 auto;
    padding:4.5% 4.5% 4.5% 5%;
    background:$color-neutral-200;

    .staff__bio-close {
        align-self:flex-start;
    }

    &::backdrop {
        background:transparentize($color-neutral-700, .2);
    }
}

.staff__bio-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:45px;
    max-width:920px;
    margin:0 auto;
    padding:0 $grid-gutter-em;
}

.staff__bio-close {
    @extend %buttonreset;
    display: flex;
    align-items: center;
    gap:12px;
    font-size:0.875rem;
}

.staff__bio-header {
    position:relative;
    display:grid;
    grid-template-columns: auto 52%;
    align-items: end;
    gap:70px;
    width:100%;
    padding:11% 7% 10% 0;
    margin-top:11%;
    background:$color-white;

    .staff__bio-media {
        position:absolute;
        top:-11%;
        left:-33px;
        width:42%;
    }

    .staff__bio-header-content {
        grid-column-start:2;
    }
}

.staff__bio-header-content {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.staff__bio-content {
    max-width:704px;
    margin:0 auto;
    font-size:1.375rem;
    color:$color-primary-800;

    &:last-child {
        margin-bottom:0;
        padding-bottom:0;
    }
}

@media(min-width:$bp-m){
    .staff__bio[open] {
        width:65%;
    }

    .staff-listing {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap:30px;
        row-gap:40px;
        align-items: start;
    }
}

@media(min-width:$bp-l){
    .staff__name {
        font-size:2rem;
    }

    .staff-listing {
        column-gap:60px;
        row-gap:80px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        .staff:first-child {
            grid-row: 1;
            grid-column:1/4;

            .staff__inner {
                width:33.3333%;
                padding-left:40px;
                margin-left:auto;
            }
        }
    }

    .staff__bio-inner {
        gap:110px;
    }

    .staff__bio-header-content {
        gap:40px;
    }

    .staff-leadership {
        .sf-content-block {
            margin-bottom:70px;

            p {
                padding-left:14%;
            }

            *:last-child {
                margin-bottom:0;
                padding-bottom:0;
            }
        }

        .staff-slider {
            position:relative;
            top:-235px;
        }
    }
}

@media(min-width:$bp-xxxl){
    .staff__bio[open] {
        padding:87px 90px 90px 100px;
    }

    .staff__bio-header {
        margin-top:90px;

        .staff__bio-media {
            top:-90px;
        }
    }

    .staff-leadership {
        .sf-content-block {
            p {
                padding-left:126px;
            }
        }
    }
}