@use '../../abstracts/' as *;

.stats-green--grid {
    padding: 0 50px;
}

.stats-green__item {
    background: $color-primary-700;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    margin: 10px 0;
    padding: 40px 30px;

    &.swiper-slide {
        height: auto;
    }
}

.stats-green__item {
    text-align: center;
}

.stats-green__heading, .stats-green__subheading, .stats-green__stat, .stats-green__summary {
    color: $color-white;
}

.stats-green__body {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.stats-green__media {
    margin-right: 20px;
}

.stats-green__img {
    height: auto;
    width: 80px;
    margin-bottom: 24px;
}

.stats-green__stat {
    font-family: $font-primary-thin;
    font-size:5.2rem;
}

.stats-green__subheading {
    flex: 1 100%;
    font-size: 1.97rem;
    font-weight: $fontWeight-semibold;
    margin-bottom: 24px;
    letter-spacing: 1.2px;
    line-height: 1.2;
}

.stats-green__summary {
    font-family: $font-primary-light;
    margin-top: 16px;
}

.stats-green__action {
    margin-top: auto;
    text-align: right;
    width: 100%;
}

.stats-green__btn {
    background:transparent;
    border: none;
    color: $color-primary-500;
    cursor: pointer;
    font-family: $font-primary-thin;
    font-size: 2rem;
    text-align: center;
}

.stats-green__dialog {
    border: none;
    font-family: $font-primary-light;
    font-size: 1rem;
    padding: 20px;
    width: 80%;
    text-align: left;

    p {
        font-size: 1rem;
    }
}

.stats-green__dialog-action {
    margin-bottom: 15px;
    text-align: right;
}

.stats-green__close {
    @extend %buttonreset;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    text-align: center;
    background: $color-secondary-700;
    cursor: pointer;

    .icon {
        fill: $color-white;
        height: 20px;
        width: 20px;
    }
}

.stats-green__pagination {
    position: relative;

    .swiper-pagination-bullet {
        background: $color-white;
        border-radius: 0;
        height: 2px;
        width: 30px;    
        opacity: 0.4;
        
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}

@media(min-width: $bp-l) {
    .stats-green--grid {
        padding: 0;
    }

    .stats-green__item {
        text-align: left;
        
        &.swiper-slide {
            height: 100%;
        }
    }

    .stats-green__header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .stats-green__media {
        margin-bottom: 0;
    }

    .stats-green__img {
        margin-bottom: 0;
    }

    .stats-green__summary {
        margin-top: 0;
    }

    .stats-green__dialog {
        width: 50%;
        padding:40px;
    }

    .stats-green--grid .stats-green__wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(200px, 1fr));
        grid-template-rows: repeat(5, auto);
        grid-auto-flow: dense;
        grid-row-gap: 35px;
        grid-column-gap: 25px;

        .stats-green__item{
            display: inline-block;
            margin: 0;
            &:first-child {
                grid-column: 1/3;
                grid-row: 1;
            }

            &:first-child ,&:nth-child(5n) {
                grid-column: 1/3;
                .stats-green__body {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;

                    .stats-green__header {
                        width: 50%;
                    }
            
                    .stats-green__summary {
                        width: 50%;
                    }
                }
               
            }

            &:nth-child(2)  {
                grid-column: 3;
                grid-row: 1/3;
            }

            &:nth-child(3)  {
                grid-column: 1;
                grid-row: 2 / 4;
            }

            &:nth-child(4) {
                grid-column: 2;
                grid-row: 2 / 4;
            }

            &:nth-child(5) {
                grid-column: 1/3;
                grid-row: 4/5;
            }

            &:nth-child(6) {
                grid-column: 3;
                grid-row: 3/5;
            }
        }
    }

    .stats-green--landscape {
        .stats-green__body {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }

        .stats-green__header {
            width: 50%;
        }

        .stats-green__summary {
            width: 50%;
        }
    }


}