@use '../../abstracts/' as *;

.card.card--icon-alt {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap:30px;
    padding:0;
    background:none;
    box-shadow:none;

    .card__body {
        display: grid;
        gap:11px;
    }

    .card__title {
         font-size:#{"clamp(1.25rem, 5vw, 1.5rem)"};
         color:$color-primary-700;

         .card__link {
            color:currentColor;
         }
    }

    .card__content {
        font-size:#{"clamp(1.25rem, 5vw, 1.25rem)"};
    }
}

@media(min-width:$bp-l){
    .card.card--icon-alt {
        .card__content {
            color:$color-primary-800;
        }
    }
}