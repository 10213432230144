@use '../../abstracts/' as *;

.timeline {
    height: 550px;
}

.timeline-slider {
    height: 100%;
}

.timeline__item {
    padding-left: 46px;
}
.timeline__year {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color-white;
    font-size: clamp(3.25rem, 6vw, 7rem);
    font-weight: $fontWeight-bold;
    margin-bottom: 24px;
}

.timeline__content {
    color: $color-white;
    font-weight: $fontWeight-bold;
    line-height: 36px;
    padding-left: 60px;
    position: relative;
}

.swiper-slide-active {
    .timeline__year {
        color: $color-white;
    }

    .timeline__content:before {
        background: $color-lime;
        position: absolute;
        top: 16px;
        left: 0;
        content: "";
        width: 40px;
        height: 2px;
    }
}

.timeline-pagination {
    --swiper-pagination-bullet-size:10px;

    .swiper-pagination-bullet {
        background: $color-white;
        opacity: 0.3;

        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }

    &.swiper-pagination-vertical {
        left: 0;
        right: auto;    
        
        .swiper-pagination-bullet {  
            margin-top: 0;
            margin-bottom: 26px;
            text-align: center;

            &:after {
                background: rgba(255,255,255,0.3);
                display: inline-block;
                content: "";
                height: 34px;
                width: 1px;
            }

            &.swiper-pagination-bullet-active-next:after {
                display: none;
            }
        }
    }

}

.timeline-prev, .timeline-next {
    color: $color-white;
    left: auto;
    right: 0;
    top: 100%;
    margin-top: -60px;

    &:after {
        display: none;
    }

    svg {
        width: 30px;
        height: 30px;
    }
}

.timeline-prev {
    right: 60px;
}


@media(min-width: $bp-mw) {
    .timeline {
        height: auto;
    }

    .timeline__item {
        opacity: 0.4;
    }

    .timeline__content {
        padding-left: 124px;
    }

    .timeline__controls {
        display: flex;
        column-gap: 50px;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide-active {   
        opacity: 1;  
    
        .timeline__content:before {
            width: 96px;
        }
    }
    
    .timeline-prev, .timeline-next, .timeline-pagination {
        display: inline-block;
        position: relative;
        left: auto;
        right: auto;
        margin: 0;
    
        &:after {
            font-size: 2rem;
        }
    }
    
    .timeline-pagination {
        width: auto !important;

        &.swiper-pagination-horizontal {
            display: flex;
            align-items: center;

            .swiper-pagination-bullet {  
                position:relative;
                margin-left: 0;
                margin-right: 49px;
                transition:width .3s ease-in-out, height .3s ease-in-out, opacity .3s ease-in-out;

                &:last-child {
                    margin-right:0;
                }
    
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;  
                    left:100%;
                    display: inline-block;
                    width: 50px;
                    height: 1px;   
                    background:$color-white;
                    opacity:0.3;
                }
    
                &:last-child:after {
                    display: none;
                }
    
                &.swiper-pagination-bullet-active {
                    width:17px;
                    height:17px;
                    opacity: 1;
                }
            }
        }
    }
    
}