@use "../../abstracts/" as *;

.video__player {
  & > div{
    max-width: 100%!important;
  }
}

.video__toggle {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;

  &.video__toggle--dark {
    .video__toggle-icon {
      fill: $color-primary-800;
    }
  }

  &:hover {
    .video__toggle-icon {
      opacity: 1;
    }
  }
}

.video__toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index:2;
  width: 40px;
  height: 40px;
  opacity: .5;
  transform: translateX(-50%) translateY(-50%);
  fill:$color-white;
  transition:opacity 0.3s ease-in-out;
}

.video__player.video__player--iframe {
  position:relative;
}

.video.video--thumbnail {
  .video__player {
    display: none;
    padding-top:0;
  }
}

@media(min-width:$bp-m){
  .video__media {
    border-radius: 15px;
    overflow: hidden;
  }

  .video__toggle-icon {
    width:80px;
    height:80px;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}