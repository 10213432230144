@use '../../abstracts' as *;

.document-list {
	display: flex;
	flex-direction: column;
	
	.document {
		margin-bottom:1rem;
		padding-bottom:1rem;
		border-bottom:1px solid #ccc;
		color:inherit;
	}
}
.media-layout--darkblue .document-list {
	.document {
		color:$color-white;
	}
}
.arrowList {
	padding: 1rem;
}

.document {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap:.5rem;
}
.tbd__list{
	margin: 2rem 0 1rem;
}
.tbd__action{
	color: inherit;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    align-items: center;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}

	.document__title {
		flex: 1;
	}
}
.tbd__link{
	color: inherit;
    display: flex;
    flex-direction: row;
    padding: 0;
    text-align: center;
    width: 26%;
    justify-content: flex-end;
    max-width: 200px;
    align-items: center;
	&--text{
		visibility: hidden;
		font-size: 0;
		width: 0;
	}
}
.tbd__link--icon{
	stroke: $color-primary-800;
	text-align: right;
    max-width: 24px;
    width: 30%;
}
.media-layout--darkblue{
	.tbd__link--icon{
		stroke: $color-white;
	}
}

.arrowList__link{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	gap: 1.5rem;
	&:hover{
		text-decoration: none;
		.document__title{
			transform: translateX(1rem);
		}
		.arrowList__arrow{
			transform: translateX(-1rem);
		}
	}
}
.document__title{
	will-change: transform;
	transition: all 0.4s;
}
.arrowList__arrow{
	min-width: 1.8rem;
	will-change: transform;
	transition: all 0.4s;
    height: 1.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjMzQ5MkVBIiBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTExLjk1IDUxMS45NSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgc3Ryb2tlPSIjMzQ5MkVBIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxnPiA8cGF0aCBkPSJNNTExLjgxMywyNTQuMTAzYy0wLjk2LTUuMjI3LTUuNjUzLTguODUzLTEwLjg4LTguODUzSDM2LjI5M2wxOTUuMi0xOTUuMDkzYzQuMDUzLTQuMjY3LDMuOTQ3LTEwLjk4Ny0wLjIxMy0xNS4wNCBjLTQuMTYtMy45NDctMTAuNjY3LTMuOTQ3LTE0LjgyNywwTDMuMTIsMjQ4LjQ1Yy00LjE2LDQuMTYtNC4xNiwxMC44OCwwLDE1LjA0bDIxMy4zMzMsMjEzLjMzMyBjNC4yNjcsNC4wNTMsMTAuOTg3LDMuOTQ3LDE1LjA0LTAuMjEzYzMuOTQ3LTQuMTYsMy45NDctMTAuNjY3LDAtMTQuODI3bC0xOTUuMi0xOTUuMmg0NjQuOTYgQzUwNy43NiwyNjYuNTgzLDUxMi44OCwyNjAuNzE3LDUxMS44MTMsMjU0LjEwM3oiLz4gPC9nPiA8L2c+IDwvZz4KDTwvc3ZnPg==);
}

.document__icon {
	width:30px;
	height:30px;
	fill:$color-primary;
}

.document__toggle {
	@extend %buttonreset;
}

@media(min-width:$bp-sw){
	.document {
		flex-direction: row;

		.document__action {
			margin-left:auto;
		}
	}
}
@media(min-width:$bp-m){
	.tbd__link{
		&--text{
			visibility: visible;
			flex: 1;
			width: auto;
			margin-top: .3rem;
			font-size: 12px;
			font-family: $font-primary-bold;
			letter-spacing: 0.05em;
			text-align: center;
			text-transform: uppercase;
		}
	}
}