@use '../../abstracts/' as *;

.card.card--poster {
    position: relative;
    z-index:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height:469px;
    border-radius: 0;
    padding:0 30px 25px;
    background-color: $color-neutral-600;
    box-shadow: none;
    color: $color-white;

    &:before {
        @include pseudo();
        top:0;
        left:0;
        z-index:0;
        width: 100%;
        height: 100%;
        background: linear-gradient(179.48deg, rgba(9, 16, 112, 0) 25.98%, rgba(6, 20, 37, 0.7) 99.6%);
    }

    .card__media {
        position:absolute;
        top:0;
        left:0;
        z-index:-1;
        width:100%;
        height:100%;
        background-size: cover;
        background-position: center;
    }

    .card__body {
        position:relative;
        z-index:1;
        color:$color-white;
    }

    .card__title {
        font-size:1.5rem;
        line-height:1.3;
    }

    .card__summary {
        font-size:1rem;
        line-height: 1.7;
    }

    .card__link {
        margin-top:30px;
    }

    .button.button--arrow .arrow {
        background: rgba(242, 241, 240, 0.35);
    }
}

// Slider Component Styles
.card-poster-slider {
    .card-slider__navigation {
        display: none;
    }

    .card-slider__buttons {
      display: flex;
      gap:30px;
    }
    
    .card-slider__button {
      @extend %buttonreset;
      position:static;
      width:30px;
      height:30px;
      color:#5A5D80;
    
      &:after {
        display: none;
      }
    
      .card-slider__button-icon {
        width:100%;
        height: 100%;
        fill:currentColor;
      }

      &:hover, &:focus-visible {
        background: none;
        color: $color-neutral-800;
      }
    }
    
    .card-slider__pagination {
        position:static;
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        margin-top:8px;
        opacity: 1;

        .swiper-pagination-bullet {
            color: transparent;
            font-size: 0;
            height: 2px;
            margin: 0;
            opacity: 1;
            width: 27%;
            background: $color-neutral-600;

            &.swiper-pagination-bullet-active {
                background:$color-primary-500;
            }
        }
    }

    &.swiper-initialized {
        .card-slider__navigation {
            display: flex;
            gap:40px;
            align-items: flex-start;
            margin-top:32px;
        }
    }
}

@media(min-width:$bp-m){
    .card.card--poster {
        min-height:600px;

        .card__summary {
            display:none;
        }
    }

    .card-poster-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap:33px;
    }
}

@media(min-width:$bp-l){
    .card.card--poster {
        .card__title {
            font-size:2.25rem;
        }
    }
}