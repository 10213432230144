@use '../../abstracts/' as *;

.hero.hero--image-left {
    position: relative;
    background: none;
    padding: 0;
    overflow: hidden;

    &:before {
        display: none;
    }

    .hero__background {
        &:before {
            background: linear-gradient(180deg, rgba(9, 16, 112, 0) 0%, rgba(6, 12, 70, 0.70) 100%);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        &-img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }

    }

    .hero__content {
        position: relative;
        padding: 5.4rem 2rem 1rem;
        z-index: 3;
    }

    .curve_background {
        display: none;
    }

    .hero__content.hero__content--second {
        padding: 0 2rem;

        .hero__download {
            display: flex;
            align-items: center;
            gap: 14px;
        }

        .download__wheel {
            width: 60px;
            height:60px;
            flex-shrink:0;
            fill:currentColor;
        }

        .download__action {
            display: flex;
            align-items: center;
            gap:14px;
            font-family: $font-primary-medium;
            font-size: #{"clamp(1.1rem, 1.75vw, 2.2rem)"};
            letter-spacing: 0.004px;
            text-align: left;
            text-transform: none;
            color: $color-white;

            .arrow {
                flex-shrink:0;
            }
        }
    }

    &--green {
        .hero-stack {
            margin-bottom: 0;
        }

        &:before {
            background: rgb(177, 202, 111);
            background: linear-gradient(0deg, rgba(183, 206, 123, 1) 33%, rgba(177, 202, 111, 1) 56%, rgba(113, 155, 63, 1) 100%);
            content: "";
            display: block;
            height: 30%;
            left: 0;
            right: 0;
            position: absolute;
            bottom: -1px;
            width: 100%;
            z-index: 2;
        }
    }
}

@media(min-width:$bp-l) {
    .hero.hero--image-left {
        max-height: 1200px;

        .hero-stack {
            margin-bottom: 0;
        }

        .hero__background {
            &:before {
                background: linear-gradient(262.47deg, rgba(6, 12, 70, 0.04) 9.86%, rgba(6, 12, 70, 0.2) 98.77%);
            }
        }

        .hero-inner {
            gap:100px;
            justify-content: flex-end;
        }

        .hero__content {
            position: relative;
            padding: 26rem 0 17rem;
            max-height: 1070px;
        }

        .hero__title {
            text-align: right;
        }

        .curve_background {
            display: block;
            background-image: url($img-path+'hero-curve.png');
            background-position: 50%;
            background-repeat: no-repeat;
            bottom: -2px;
            height: 100%;
            position: absolute;
            right: -2px;
            width: 100%;
            background-size: 100% 100%;
            z-index: 1;
        }

        .hero__content.hero__content--second {
            display: grid;
            grid-template-columns: 1fr 51%;
            padding: 0 0 4rem;

            .hero__download {
                gap:40px;
                grid-column:2;
            }

            .download__wheel {
                width:115px;
                height:115px;
            }

            .download__action {
                gap:40px;
                font-family: $font-primary-light;
                text-transform: inherit;
            }
        }

        &-green {
            &:before {
                display: none;
            }

            .curve_background {
                background-image: url($img-path+'greenGradient_curve.png');
            }

            .hero__content {
                padding: 13rem 0 5rem;
                display: inline-grid;
                gap: 5%;
                grid-template-columns: 44% 1fr;
            }

            .hero-textbox {
                max-width: 94%;
            }

            .hero__title {
                text-align: right;
            }
        }
    }
}