@use '../../abstracts/' as *;

.site-search__toggle {
    @extend %buttonreset;
}

.site-search__icon {
    width:20px;
    height:20px;
}

.site-search__controls {
    display:none;
    position: absolute;
    top:100%;
    right:0;
    z-index:10;
    align-items: center;
    flex-wrap:wrap;
    gap:10px;
    width:100%;
    margin:0 auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding:16px;
    background:transparentize($color-white, .15);
    box-shadow: 4px 4px 15px rgba(0,0,0,.1);

    &:focus-within:has(input:not(:placeholder-shown)) {
        label {
            opacity:1;
        }
    }
}

input[type="text"].site-search__input {
    width:80%;
    border:0;
    border-radius: 0;
    border-bottom:1px solid $color-neutral-500;
    padding-top:10px;
    padding-bottom:10px;
    background:none;
    color:$color-primary-700;

    &::placeholder {
        color:$color-primary-700;
    }
}

.site-search__label {
    position:absolute;
    top:5px;
    left:32px;
    font-size:0.75rem;
    opacity: 0;
    color:$color-primary-700;
    transition:opacity .3s ease-in-out;
}

button.site-search__submit {
    @extend %buttonreset;
    position: absolute;
    top:0;
    right:0;
    width:20%;
    height:100%;
    max-height:95px;
    border:0;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    padding:0;
    background:$color-primary-700;
    color:$color-white;
}

.site-search__suggestions {
    @extend %listreset;
    position: absolute;
    top:100%;
    left:0;
    width:100% !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding:0 0 20px 30px;
    background:transparentize($color-white, .15);

    button {
        @extend %buttonreset;
        text-align: left;
        width:100%;
        border-radius: 8px;
        padding:5px;
        background:transparent;
        transition:background .3s ease-in-out;

        &:hover, *:focus-visible {
            background:$color-neutral-600;
            color:$color-white;
        }   
    }
}

.site-search__status {
    color:$color-error-2;
}

@media(min-width:$bp-mw){
    .site-search {
        position:relative;
    }

    .site-search__controls {
        width: 808px;
        max-width:none;
        padding:20px 25% 20px 30px;
    }

    .site-search__input {
        width:90%;
    }

    .site-search__submit {
        width:10%;
    }
}