/* ================================================================
   Flex Grid - Bootstrap Inspired
================================================================ */
@use '../abstracts' as *;

$grid-columns:12;
$colAlignments:"flex-start","center","flex-end";

.container {
  width:100%; 
  max-width:$grid-container-width;
  margin-right: auto; 
  margin-left: auto;
  padding-left: $grid-gutter-em;
  padding-right: $grid-gutter-em;
}

.row { 
    display:flex;
    flex-wrap:wrap;
    margin-left:-$grid-gutter-em;
    margin-right:-$grid-gutter-em;

    & > * {
        position:relative;
        width:100%;
        max-width:100%;
        flex-shrink:0;
        padding-left:$grid-gutter-em;
        padding-right:$grid-gutter-em;
    }

    &.row--col-shrink {
        & > * {
            flex-shrink:1;
        }
    }

    .container &,
    [class*="col-"] & {
        margin-left:-$grid-gutter-em;
        margin-right:-$grid-gutter-em;
    }
}

.align-items-center {
    align-items:center;
}

@each $alignment in $colAlignments {
    .align-#{$alignment} {
        align-items:$alignment;
    }

    .justify-#{$alignment} {
        justify-content: $alignment;
    }
}

@for $i from 1 through $grid-columns {
    .order-#{$i} {
        order:$i;
    }

    .col-#{i},
    .row-cols-#{i} > * {
        flex: 0 0 auto;
        width:calc($i/$grid-columns * 100%);
    }
}

@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @each $alignment in $colAlignments {
            .align-#{$bpAbbv}-#{$alignment} {
                align-items:$alignment;
            }
        
            .justify-#{$bpAbbv}-#{$alignment} {
                justify-content: $alignment;
            }
        }

        @for $i from 1 through $grid-columns {
            .col-#{$bpAbbv}-#{$i},
            .row-cols-#{$bpAbbv}-#{$i} > * {
                flex:0 0 auto;
                width:calc($i/$grid-columns * 100%);
            }

            .order-#{$bpAbbv}-#{$i} {
                order:$i;
            }
        }   
    }
}