@use '../../abstracts/' as *;
@use 'sass:math';

.card--green-wrapper{
    padding: 0;
}

.card.card--green {
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    position: relative;
    background:none;
    padding:0;

    .card__media {
        background:no-repeat center/cover;
        position: relative;
        width: 100%;
        z-index: 1;
        &::after{
            content: "";
            display: block;
            padding-top: math.percentage(math.div(655,917));
          }
    }
    .card__title {
        z-index: 2;
        position: relative;
        padding:1rem 0;
    }
    .card_main{
        padding:2.5rem 1.5rem;
    }
    .card__content{
        font-size: clamp(1rem,6vw,1.25rem);
        letter-spacing: .91px;
        line-height: 1.7;
    }
    .card__body {
        padding:0;
        position: relative;
        height: 100%;
    }
    .card__body-inner{
        position: relative;
        width: 100%;
    }
    .card__body--green {
        position: absolute;
        top: -12rem;
        z-index: 2;
        width: 85%;
        left: 50%;
        transform: translate(-50%,0);
        gap:32px;
        padding:40px 40px 32px 50px;
        background: linear-gradient(105.92deg, #A8BF15 2.97%, #658D30 85.65%);
        color: $color-white;
        font-family: $font-primary-light;

    .card__subheading{
        font-size: 1.5rem;
        line-height: 1.3;
        color: $color-white;
    }

    }

    .card__subheading {
        font-size:#{"clamp(1.875rem, 5vw, 2.5rem)"};
        color:$color-primary-700;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
    .button{
        font-family: $font-primary-bold;
    }
}

@media(min-width:$bp-mw){
    .card.card--green {
        position:relative;
        display: inline-grid;
        gap: 3.5%;
        grid-template-columns: 40% 1fr;
        align-items: start;

        .card__title {
            font-size: 4rem;
        }
        .card__media--wrapper{
            position: absolute;
            height: 100%;
            width: 100%;
        }
        .card__body-inner{
            height: 100%;
        }
        .card__body--green {
            width: 67%;
            top:auto;
            bottom: -8rem;
            padding: 40px 60px 40px 75px;
            font-family: $font-primary-bold;
            p{
                font-size: 20px;
                line-height: 34px;
                margin: 0.5rem 0;
            }
            &:before {
                @include pseudo();
                top:53px;
                left:-35px;
                width:70px;
                height:2px;
                background:rgba(214, 222, 35, 1);
              }
        }

        .card__title {
            padding: 3rem 0 0;
            position: relative;
            text-align: left;
        }

    }
}

@media(min-width:$bp-xxxl){
    .card.card--green {
        .card__body--green {
            bottom: -14rem;
        }

    }
}
