@use '../../abstracts/' as *;

.card.card-blue-square {
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    padding: 0 0 3rem;
    background: none;
    .card__main{
        width: 100%;
    }
    .card__media {
        background:no-repeat center/cover;
        position: relative;
        width: 100%;
        z-index: 1;
        &::after{
            content: "";
            display: block;
            padding-top: 80.354369%;
          }
    }
    .card__title {
        color: $color-white;
        font-size: clamp(2.75rem,5vw,5rem);
        position: relative;
        padding: 0;
        padding-bottom: 24px;
        font-size: 44px;
        line-height: 1.2;
    }

    .card__body {
        position:relative;
        border-bottom-left-radius: 50px;
        margin-left: 2rem;
        margin-top: -100px;
        padding: 45px 34px 60px;
        position: relative;
        z-index: 2;
        padding:45px 34px 60px;
        background:$color-primary-500;
        font-size:#{"clamp(2rem, 5vw, 2.5rem)"};
        line-height: 1.2;
        color:$color-white;
    }

    .card__content {
        font-family: $font-primary-light;
        font-size: clamp(1.25rem,6vw,1.7rem);
        line-height: 44px;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }

    &:after {
        display:none;
    }
}

@media(min-width:$bp-mw){
    .card.card-blue-square {
        position:relative;
        .card__main{
            align-items: start;
            display: inline-grid;
            gap: 5%;
            grid-template-columns: 48% 1fr;
            padding: 8% 4.5% 0 4.5%;
            position: relative;
        }

        .card__media-wrapper{
            position: relative;
            display: flex;
        
            &:before {
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 30px 30px;
                content: "";
                display: block;
                height: 114px;
                position: absolute;
                right: -35px;
                top: -40px;
                width: 108px;
                z-index: 1;
            }
        }

        .card__title {
            font-size: 80px;
            line-height: 1;
            padding-bottom: 0.5rem;
        }

        .card__body {
            margin-left: -35%;
            margin-right: 0;
            margin-top: 31%;
            padding: 9%;
            z-index: 2;
        
        }


    }
}

@media(min-width:$bp-xxxl){
    .card.card-blue-square {
        .card__main{
            padding: 5% 4.5% 0 4.5%;
        }
    
    }
}
