@use '../../abstracts/' as *;

.hero-slide {
  position: relative;
  padding: 100px 24px 164px;
  color: $color-white;

  &.swiper-slide {
    height:auto;
  }
}

.hero-slide__media,
.hero-slide__media:after,
.hero-slide__img {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.hero-slide__media:after{
  @include pseudo();
  background:rgba(6, 12, 70, 0.40);
}

.hero-slide__img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.hero-slide__content {
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  gap:32px;
}

.hero-slide__title {
  font-family:$font-primary-thin;
  font-size:#{"clamp(3rem, 5.3vw, 115px)"};
  text-shadow:0px 0px 12.5px rgba(0, 0, 0, 0.40);
  line-height: 1.05;
}

.hero-slide__text {
  position:relative;
  display: flex;
  flex-direction: column;
  gap:32px;
  border-bottom-left-radius: 50px;
  border-top-right-radius:50px;
  padding:40px 40px 32px 50px;
  background: rgba(121, 156, 75, 0.9);
  backdrop-filter: blur(12px);
  font-family: $font-primary-light;
  font-size:#{"clamp(1.125rem, 1.5vw, 1.75rem)"};
  font-weight: $fontWeight-light;
  line-height:1.57;

  &:before {
    @include pseudo();
    top:53px;
    left:-23px;
    width:50px;
    height:1px;
    background:$color-white;
  }

  .button {
    align-self:flex-end;
    color:$color-white;

    &.button--arrow  {
      .arrow {
        background-color: transparentize($color-neutral-200, .7);
      }

      &:hover, &:focus-visible, &:active {
        color:$color-white;

        .arrow {
          background:transparent;
        }
      }
    }
  }
}

/* Slider Component Styles
========================================================= */
.hero-slider,
.hero-slider > .swiper-wrapper {
  z-index:0;
}

// Arrows/Buttons
.hero-slider {
  .hero-slider__buttons {
    display: flex;
    gap:20px;
    position:absolute;
    right:32px;
    bottom:105px;
  }
  
  .hero-slider__button {
    @extend %buttonreset;
    position:static;
    width:22px;
    height:22px;
    color:$color-white;
  
    &:after {
      display: none;
    }
  
    .hero-slider__button-icon {
      width:100%;
      height: 100%;
    }
  }
}

// Pagination
.hero-slider .hero-slider__pagination {
  position:absolute;
  top:auto;
  left:41px;
  right:110px;
  bottom:110px;
  display: flex;
  flex-wrap:wrap;
  gap:20px;
  opacity: 1;

  .swiper-pagination-bullet {
    width:10px;
    height: 10px;
    margin:0;
    opacity:1;
    font-size:0;
    background:rgba(255,255,255,.5);
    color:transparent;
    transition:background .3s ease-in-out;

    &.swiper-pagination-bullet-active {
      background:$color-white;
    }
  }
}

/* Media Queries
========================================================= */
@media(min-width:$bp-l){
  .hero-slide {
    padding-top:#{"clamp(100px, 4.2vw, 160px)"};
    padding-bottom:#{"clamp(164px, 5.24vw, 180px)"};
    padding-left:#{"clamp(60px, 6.25vw, 120px)"};
    padding-right:45px;
  }
  
  .hero-slide__content {
    align-items: center;
    flex-direction: row;
    max-width:1418px;
    margin:0 auto;

    .hero-slide__title {
      max-width:48.5%;
    }

    .hero-slide__text {
      margin-left: auto;
      max-width: 40.7%;
      padding:4.8% 5.3% 30px 6.4%;
      
      &:before {
        top:80px;
        width:100px;
        left:-50px;
      }
    }
  }

  .hero-slide__text {
    border-bottom-left-radius: 50px;
  }

  .hero-slider {
    .hero-slider__pagination {
      left:#{"clamp(20px, 3.1vw, 80px)"};
      top:50%;
      bottom:auto;
      right: auto;
      width: auto;
      flex-direction: column;
      transform:translateY(-50%);
    }
  
    .hero-slider__buttons {
      left:0;
      right:0;
      bottom:20px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap:30px;
  
      .swiper-button-next {
        margin-left:72px;
      }
  
      .hero-slider__button {
        margin-top:0;
        grid-column-start:2;
        grid-row-start:1;
      }
    }
  
    .hero-slider__button {
      width:32px;
      height:32px;
    }
  }
}

@media(min-width:$bp-xxl){
  .hero-slide__text {
    padding:67px 75px 30px 90px;
  }
}