@use '../abstracts' as *;

.sf-section,
.sf-col {
    background-color: var(--sf-backgrоund-color);
}

.sf-section--row {
    padding-left: $grid-gutter-em;
    padding-right: $grid-gutter-em;
  }

.sf-section.bg-img {
    background-image: var(--sf-backgrоund-image);
    background-size: var(--sf-background-size);
    background-position: var(--sf-background-position);
    background-repeat: no-repeat;
}

.sf-section.sf-section--video {
    position:relative;
    z-index:1;
}

.sc-video__wrapper {
    position: absolute;
    z-index:-1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding:0 1.875rem;
    overflow: hidden;
}

.sc-video__toggle {
    @extend %buttonreset;
    position:absolute;
    bottom:1rem;
    left:1rem;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    z-index:1;

    .sc-video__pause-icon {
        display: none;
    }

    &.active {
        .sc-video__pause-icon {
            display: block;
        }

        .sc-video__play-icon {
            display: none;
        }
    }  
}

.sc-video__element {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
}

.-sc-video {
    position: relative;
}

.sf-col {
    background-color:var(--sf-backgrоund-color);
}

.tl-concave,
.tr-concave {
    position: relative;
    margin-top:11.3%;
    background:$color-white;

    &:before {
        @include pseudo();
        bottom:100%;
        left:0;
        width:100%;
        padding-top:11.3%;
        background-size:100% auto;
        background-repeat: no-repeat;
    }
}

.tl-concave:before {
    background-image:url($img-path+'tl-concave.png');
}

.tr-concave:before {
    background-image:url($img-path+'tr-concave.png');
}

.bl-concave,
.br-concave {
    position: relative;
    margin-bottom:11.3%;
    background:$color-white;

    &:after {
        @include pseudo();
        top:100%;
        left:0;
        width:100%;
        padding-top:11.3%;
        background-size:100% auto;
        background-repeat: no-repeat;
    }
}

.br-concave:after {
    background-image:url($img-path+'br-concave.png');
}

.bl-concave:after {
    background-image:url($img-path+'bl-concave.png');
}

.tl-convex,
.tr-convex {
    position: relative;
    margin-top:11.4%;
    background:$color-white;

    &:before {
        @include pseudo();
        bottom:100%;
        left:0;
        width:100%;
        padding-top:11.4%;
        background-size:100% auto;
        background-repeat: no-repeat;
    }
}

.tl-convex:before {
    background-image:url($img-path+'tl-convex.png');
}

.tr-convex:before {
    background-image:url($img-path+'tr-convex.png');
}

.bl-convex,
.br-convex {
    position: relative;
    margin-bottom:11.4%;
    background:$color-white;

    &:after {
        @include pseudo();
        top:100%;
        left:0;
        width:100%;
        padding-top:11.4%;
        background-size:100% auto;
        background-repeat: no-repeat;
    }
}

.bl-convex:after {
    background-image:url($img-path+'bl-convex.png');
}

.br-convex:after {
    background-image:url($img-path+'br-convex.png');
}

.skyblue-yellow-gradient {
    background:$color-neutral-300;

    // Concave Variations
    &.tl-concave:before {
        background-image:url($img-path+'tl-concave-blue.png');
    }

    &.tr-concave:before {
        background-image:url($img-path+'tr-concave-blue.png');
    }

    &.bl-concave:after {
        background-image:url($img-path+'bl-concave-yellow.png');
    }
    
    &.br-concave:after {
        background-image:url($img-path+'br-concave-yellow.png');
    }

    // Convex Variations
    &.tl-convex:before {
        background-image:url($img-path+'tl-convex-blue.png');
    }

    &.tr-convex:before {
        background-image:url($img-path+'tr-convex-blue.png');
    }

    &.bl-convex:after {
        background-image:url($img-path+'bl-convex-yellow.png');
    }
    
    &.br-convex:after {
        background-image:url($img-path+'br-convex-yellow.png');
    }
}

.brown-gradient {
    background:linear-gradient(180deg, #9c8c8e 0, #a28c8d 30%);

    // Concave Variations
    &.tl-concave:before {
        background-image:url($img-path+'tl-concave-brown.png');
    }

    &.tr-concave:before {
        background-image:url($img-path+'tr-concave-brown.png');
    }

    &.bl-concave:after {
        background-image:url($img-path+'bl-concave-brown.png');
    }
    
    &.br-concave:after {
        background-image:url($img-path+'br-concave-brown.png');
    }

    // Convex Variations
    &.tl-convex:before {
        background-image:url($img-path+'tl-convex-brown.png');
    }

    &.tr-convex:before {
        background-image:url($img-path+'tr-convex-brown.png');
    }

    &.bl-convex:after {
        background-image:url($img-path+'bl-convex-brown.png');
    }
    
    &.br-convex:after {
        background-image:url($img-path+'br-convex-brown.png');
    }


}

.lime-green-gradient-transparent {
    position:relative;
    color:$color-white;

    &:before {
        @include pseudo();
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:linear-gradient(180deg,#bacca6 4%, #5a8228 45%);
        opacity:.85;
    }

    & > * {
        position:relative;
    }
}

.lime-green-gradient {
    position:relative;
    background:linear-gradient(to bottom, $color-secondary-400 0%, $color-secondary-700 100%);
    color:$color-white;

    // Concave Variations
    &.tl-concave:before {
        background-image:url($img-path+'tl-concave-green.png');
    }

    &.tr-concave:before {
        background-image:url($img-path+'tr-concave-green.png');
    }

    &.bl-concave:after {
        background-image:url($img-path+'bl-concave-green.png');
    }
    
    &.br-concave:after {
        background-image:url($img-path+'br-concave-green.png');
    }

    // Convex Variations
    &.tl-convex:before {
        background-image:url($img-path+'tl-convex-green.png');
    }

    &.tr-convex:before {
        background-image:url($img-path+'tr-convex-green.png');
    }

    &.bl-convex:after {
        background-image:url($img-path+'bl-convex-green.png');
    }
    
    &.br-convex:after {
        background-image:url($img-path+'br-convex-green.png');
    }
}
.green-transparent-gradient {
    background: linear-gradient(0deg,#396613 .09%,rgba(144,180,38,.95) 80.93%,rgba(144,180,38,0) 100%);
}

.light-blue-gradient {
    background:$color-secondary-300;
    color:$color-white;
}

.skyblue-gradient {
    background:$color-secondary-50;

    // Concave Variations
    &.tl-concave:before {
        background-image:url($img-path+'tl-concave-blue.png');
    }

    &.tr-concave:before {
        background-image:url($img-path+'tr-concave-blue.png');
    }

    &.bl-concave:after {
        background-image:url($img-path+'bl-concave-blue.png');
    }
    
    &.br-concave:after {
        background-image:url($img-path+'br-concave-blue.png');
    }

    // Convex Variations
    &.tl-convex:before {
        background-image:url($img-path+'tl-convex-blue.png');
    }

    &.tr-convex:before {
        background-image:url($img-path+'tr-convex-blue.png');
    }

    &.bl-convex:after {
        background-image:url($img-path+'bl-convex-blue.png');
    }
    
    &.br-convex:after {
        background-image:url($img-path+'br-convex-blue.png');
    }
}

.skyblue-gradient-light {
    background:$color-neutral-25;
}

.green-light-green-gradient {
    background: $color-primary-500;
    
}

/* Media Layout
================================================================ */
.media-layout {
    display:flex;
    flex-direction:column;

    &.media-layout--darkblue,
    &.media-layout--whitegray {

        .media-layout__content {
            padding:56px 40px;
            background:$color-primary-800;
            color:$color-white;
        }
    }

    &.media-layout--whitegray {

        .media-layout__content {
            position:relative;
            margin-top:-15px;
            margin-right:24px;
            padding:40px 56px;
            font-family: $font-primary-light;
            background:$color-primary-500;
            color:$color-white;

            p {
                margin:24px 0 32px;
            }
        }
    }

    &.media-layout--green {
        display: block;

        .media-layout__media {
            position: relative;
        }

        .media-layout__content {
            padding:56px 40px;
            background:linear-gradient(180deg, #A8BF15 -2.1%, #658D30 92.05%);
            color:$color-white;

            p {
                margin:24px 0 32px;
            }
        }
    }
}

/* Grid Layout
================================================================ */

.grid-layout {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(3,minmax(0,1fr)); 
    position: relative;
    &__sticky{
        position: sticky;
        top: 1rem;
    }
}

.col-span-6 {
    grid-column: span 6/span 6;
}
.flex-center{
    display: flex;
    justify-content: center;
}


/* Media Queries
================================================================ */
@media(min-width:$bp-mw){
    .media-layout {

        &.media-layout--darkblue,
        &.media-layout--whitegray {
            flex-direction: row;
            align-items: flex-start;
            max-width:1806px;
            margin-left:auto;
            margin-right:auto;
            padding:44px 4.5% 0;

            .media-layout__media {
                position:relative;
                width:56.1%;
                padding-top:48%;
                background: linear-gradient(45deg, rgba(247, 247, 247, 0.00) 50%, rgba(247, 247, 247, 0.33) 50%, #F7F7F7 50%);

                &:before {
                    @include pseudo();
                    top:-44px;
                    right:-39px;
                    z-index:1;
                    width:108px;
                    height:114px;
                    background-image: radial-gradient(rgba(9,16,112,1) 5%,transparent 7%);
                    background-position: 0 0;
                    background-size: 30px 30px;
                }

                picture, img {
                    position:absolute;
                    top:0;
                    left:0;
                    height:100%;
                    overflow: hidden;
                }

                picture {
                    width:100%;
                }

                img {
                    max-width: none;
                }
            }

            .media-layout__content {
                position:relative;
                width:53.2%;
                margin-top:100px;
                margin-left:-9%;
                padding:5.3%;
            }
        }

        &.media-layout--whitegray {
            position: relative;
            z-index: 1;
            max-width: none;
            padding-bottom:140px;
            
            &:before {
                @include pseudo();
                bottom:0;
                left:0;
                width:100%;
                height:50%;
                background:$color-neutral-100;
            }

            .media-layout__inner {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                max-width:1806px;
            }

            .media-layout__media {
                width:58.3%;
            }

            .media-layout__content {
                width:41.7%;
                margin-top:75px;
                margin-right:0;
                padding:64px 72px;

                p {
                    font-size:1.25rem;
                }
            }
        }

        &.media-layout--green {
            position:relative;
            max-width:1534px;
            margin:0 auto;

            .media-layout__media {
                position:absolute;
                top:65px;
                left:0;
                width: 58%;
                padding-top:36%;
                border-radius:15px;
                overflow: hidden;

                picture, 
                img, 
                .video,
                & > *:not(sf-add-widget) {
                    position:absolute !important;
                    top:0;
                    left:0;
                    min-width:100%;
                    height:100%;
                    object-fit: cover;
                }

                .video__player.video__player--iframe {
                    position:static;
                    padding-top:0;
                }
            }

            .media-layout__content {
                display: grid;
                grid-template-columns:58% 1fr;
                grid-template-rows:auto auto;
                column-gap:19px;
                row-gap: 32px;
                padding:0 0 0 96px;
                background:none;
                color: unset;


                .media-layout__content-title {
                    grid-column:1/4;
                    grid-row-start: 1;
                    
                    & > * {
                        max-width:37.5%;
                        margin-left:auto;
                    }
                }

                .media-layout__content-body {
                    grid-column:1/4;
                    grid-row-start:2;

                    & > * {
                        max-width:37.5%;
                    }
                }
            }

            .media-layout__content-title {
                padding: 4.5% 6% 5.5%;
            }

            .media-layout__content-body {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 4.5% 6% 5.5%;
                border-bottom-right-radius: 50px;
                background:linear-gradient(90deg, #A8BF15 -2.1%, #658D30 92.05%);
                font-family: $font-primary-light;
                color:$color-white;

                 p {
                    margin-top:0;
                    font-size:1.25rem;
                 }
            }
        }
    }
    .green-transparent-gradient {
        background:linear-gradient(0deg,#396613 .09%,rgba(134,169,29,0.85) 62%,rgba(144,180,38,0) 100%);
    }

    .green-gradient{
        position: relative;
        
        &::before{
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(178deg, rgba(102, 139, 43, 1) 0%, rgba(173, 184, 53, 1) 0%, rgba(128, 128, 128, 0.04) 75%);
        }
    }
    
    .green-gradient__inner{
        background: linear-gradient(135deg, rgba(102, 139, 43, 1) 0%, rgba(207, 222, 44, 1) 40%,rgba(128, 128, 128, 0.04) 79%, rgba(128, 128, 128, 0.04) 100%);
        padding: 3rem 0 0;
        margin: 0;
    }
    .green-gradient__img{
      display: none;
    }

    .green-gradient__content{
        z-index: 5;
        color: white;
        padding: 8rem 1rem 7rem 8rem;
        text-align: left;
        h2,h3{
           margin-bottom: 1.7rem;
        }
    }
    .green-gradient{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right bottom;
    }

    .grid-layout {
        gap: 1rem;
        grid-template-columns: repeat(6,minmax(0,1fr));
        position: relative;
    }
    .lg\:col-span-2 {
        grid-column: span 2/span 2;
    }
    .lg\:col-span-4 {
        grid-column: span 4/span 4;
    }
}

@media(min-width:$bp-xxl){
    .media-layout {
        &.media-layout--darkblue,
        &.media-layout--whitegray {
            .media-layout__content {
                padding:96px;
            }
        }

        &.media-layout--green {
            .media-layout__media {
                padding-top:550px;
                top:auto;
                bottom:109px;
            }

            .media-layout__content {
                row-gap:65px;
            }

            .media-layout__content-title,
            .media-layout__content-body {
                padding:69px 87px 78px;
            }
        }
    }
}