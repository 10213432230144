@use '../../abstracts/' as *;

.tab {
    @extend %buttonreset;
    position:relative;
    display: flex;
    align-items: center;
    gap:20px;
    width: 100%;
    border-top:1px solid $color-neutral-500;
    padding:24px 0 24px;
    font-family: $font-primary-bold;
    font-size:1.25rem;
    text-align: left;
    color:$color-primary-700;

    &:nth-last-child(2) {
        border-bottom:1px solid $color-neutral-500;
    }

    .tab__indicator {
        margin-left:auto;
        flex-shrink: 0;
    }
}

.tab__icon {
    display:none;
}

.tab__indicator {
    position:relative;
    width:20px;
    height:20px;

    &:before, &:after {
        @include pseudo();
        background:currentColor;
    }

    &:before {
        top:0;
        right:10px;
        width:1px;
        height:100%;
        transform:rotate(0);
        will-change: transform;
        transition: transform .3s ease-in-out;
    }

    &:after {
        top:10px;
        right:0;
        width:100%;
        height:1px;
    }

    .active &:before {
        transform:rotate(90deg);
    }
}

.tab-panel {
    display: none;
    padding-bottom:24px;

    &:last-child {
        border-bottom:1px solid $color-black;
    }

    &.active {
        display: block;
    }
}

.tabs-wrap.tabs-wrap--vertical {
    .tab {
        .tab__img {
            flex-shrink: 0;
        }
    }
    
    .tab__img {
        width:50px;
    }
}

.tabs-list {
    display: none;
}

@media(min-width:$bp-l){
    .tabs-list {
        display: flex;
        margin:0;
        padding:0;
        list-style-type: none;
    }

    .tab.tab--mobile {
        display: none;
    }

    .tab {
        position:relative;
        display: block;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top:0;
        margin-bottom:-1px;
        padding:25px 3.75em;
        font-family: $font-primary;
        font-size:1.375rem;
        text-align: center;
        white-space: nowrap;
        color:$color-neutral-800;
        transition:background .3s ease-in-out, border-bottom-color .3s ease-in-out, color .3s ease-in-out;

        &:nth-last-child(2) {
            border-bottom:0;
        }

        &::before {
            display: none;
        }

        &:after {
            @include pseudo();
            top:100%;
            left:50%;
            right: auto;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border:12px solid transparent;
            border-top-color:transparent;
            background-color: transparent;
        }

        &.active {
            border-bottom-color:$color-primary-500;
            background:$color-primary-500;
            color:$color-white;

            &:after {
                border-top-color:$color-primary-500;
            }
        }
    }
    
    .tab__icon {
        display: block;
        width:20px;
        height:20px;
        margin-left:auto;
        flex-shrink:0;
        color:$color-secondary-300;
    }

    .tab__indicator {
        display:none;
    }

    .tab-panel {
        display: none;
        border-top:1px solid $color-black;
        border-bottom:0 !important;
        padding-top:46px;
    }

    .tabs-wrap.tabs-wrap--vertical {
        display: grid;
        grid-template-columns: 458px 1fr;
        grid-template-rows:auto;
        column-gap:60px;

        .tab {
            display: flex;
            grid-column:1/1;
            grid-row:auto/auto;
            gap:40px;
            border-radius: 0;
            border-top:1px solid #CBD0D5;
            margin-bottom:0;
            padding:32px 40px;
            font-family: $font-primary-light;
            text-align: left;
            white-space: normal;
            color:$color-primary-700;

            &:after {
                display: none;
            }

            &.active {
                background:$color-white;
            }

            &:nth-last-child(2) {
                border-bottom:1px solid #CBD0D5;
            }
        }

        .tab__img {
            width:70px;
        }

        .tab-panel {
            grid-row:1/100;
            grid-column:2/2;
            transform:translateX(-60px);
            border:0;
            padding:0;
            opacity:0;
            transition:transform .3s ease-in-out, opacity .3s ease-in-out;

            &.tab-panel--active {
                display: block !important;
            }

            &.tab-panel--animate {
                opacity: 1;
                transform:translateX(0);
            }
        }
    }
}