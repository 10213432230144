@use '../abstracts' as *;

.breadcrumbs {
    padding:25px 0;
    overflow-x: auto;
}

.breadcrumbs__list {  
    @extend %listreset;
    display: flex;
    gap:10px;
}

.breadcrumbs__item { 
    display:inline-flex; 
    gap:10px;
    align-items: center;
    font-size:1.0625rem;
    font-family: $font-primary-light;
    white-space: nowrap;
    color:#5A5D80;

    &:after {
        @include pseudo(block, static, '');
        padding:3px;
        border:solid #C7C9CC;
        border-width:1px 1px 0 0;
        transform:rotate(45deg);
    }
    
    &:last-child {
        &:after { display:none; }
    }
}

.breadcrumbs__link {
    color:$color-primary;
}

.breadcrumbs__active {
    font-family: $font-primary-bold;
}

@media(min-width:$bp-l){
    .breadcrumbs {
        position: relative;
        z-index: 2;

        &:before {
            @include pseudo();
            top:0;
            left:0;
            z-index:-1;
            width:90%;
            height:100%;
            background: $color-white;
        }

        .hero + & {
            position:relative;
            z-index:2;
            margin-top:-74px;
        }
    }
}