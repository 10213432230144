@use '../../abstracts/' as *;
@use 'sass:math';

.card.card--grayBox-overlap {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    overflow: visible;
    position: relative;
    background:none;
    &::before{
        @include pseudo();
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-secondary;
        top:0;
        right: 0;
        z-index: 1;
    }


    .card__media {
        background:no-repeat center/cover;
        background-position-y: 80%;
        position: relative;
        width: 100%;
        z-index: 1;
        &::after{
            content: "";
            display: block;
            padding-top: math.percentage(math.div(349,824));
          }
    }
    .card__title {
        z-index: 2;
        position: relative;
        padding:2.5rem 1.5rem;
    }

    .card__body {
        padding:0 24px;
        z-index: 2;
        position: relative;
    }
    .card__body-inner {
        padding: 2rem 0;
     }

    .card__subheading {
        font-size:#{"clamp(1.875rem, 5vw, 2.5rem)"};
        color:$color-primary-700;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
}

@media(min-width:$bp-mw){
    .card.card--grayBox-overlap {
        position:relative;
        display: inline-grid;
        grid-template-columns: 40% 1fr;
        align-items: start;
        gap:5%;
        padding:0 4.5% 7% 4.5%;
        &::before{
            height: 93%;
            right: 0;
            top: 7%;
            width: 80%;
            z-index: 1;
        }

        .card__title {
            font-size: 4rem;
        }

        .card__body-inner {
            padding: 3rem 0 0;
        }

        .card__title {
            padding: 0px 1rem 0 4rem;
            position: relative;
            top: 30%;
            text-align: right;
        }

        .card__body {
            padding:0;
        }
    }
}

@media(min-width:$bp-xxxl){
    .card.card--grayBox-overlap {
        .card__title {
            font-size: 5rem;
        }
    }
}
