@use '../../abstracts/' as *;

.search-results__controls {
    display: flex;
    flex-direction: column;
    gap:16px;
    
    .search-results__display,
    .search-results__lang {
        align-self: center;
    }
}

.search-results__sort {
    display: flex;
    align-items: center;
    gap:10px;
    white-space: nowrap;
}

.search-results__display-toggle {
    @extend %buttonreset;
    color:$color-neutral-600;

    &.active {
        color:$color-primary-800;
    }
}

.search-results__display-icon {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.search-results__lang {
    position: relative;
}

.search-results__lang-toggle {
    @extend %buttonreset;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-family: $font-primary-bold;
    font-size: .75rem;
    padding: 10px;
    line-height: 2;
    text-transform: uppercase;

    &:after {
        @include pseudo(inline-block, static, '');
        border: solid;
        border-width: 0 1px 1px 0;
        margin-bottom: 3px;
        padding: 3px;
        transform: rotate(45deg);
    }
}

.search-results__lang-list {
    @extend %listreset;
    display: none;
    flex-direction: column;
    gap:5px;
    background: hsla(0,0%,95%,1);
    border-radius: 0 0 5px 5px;
    display: none;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    box-shadow: 4px 4px 15px rgba(0,0,0,.1);
}

.search-results__lang-item {
    border-bottom: 1px solid $color-neutral-500;
    padding:8px;
    text-align: center;

    &:last-child {
        border-bottom:0;
    }
}

.search-results__lang-link {
    font-family: $font-primary-bold;
    font-size: .75rem;
    text-decoration: none;
    text-transform: uppercase;
    color: $color-neutral-700;
}

.search-results__list {
    display: grid;
    gap:16px;

    &.search-results__list--grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media(min-width:$bp-m){
    .search-results__controls {
        flex-direction: row;
        

        .search-results__lang {
            margin-left:auto;
        }
    }
}