@use '../abstracts' as *;

.main-nav-toggle {
    @extend %buttonreset;
    position:relative;
    z-index:10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-nav-toggle__icon {
    display: flex;
    flex-direction: column;
    gap:5px;
    width:30px;
    color:$color-primary-800;

    .main-nav-toggle__icon-bar {
        width:100%;
        height:1px;
        background:currentColor;
        transform:none;
        opacity:1;
        transition:opacity .3s ease-in-out, transform .3s ease-in-out;
    }

    .main-nav-open & {
        color:$color-white;

        .main-nav-toggle__icon-bar {
            position:relative;
            top:0;
        }

        .main-nav-toggle__icon-bar:first-child {
            transform:rotate(45deg);
        }

        .main-nav-toggle__icon-bar:nth-child(2){
            opacity:0;
        }

        .main-nav-toggle__icon-bar:nth-child(3) {
            top:-78%;
            transform:rotate(-45deg);
        }
    }
}

.main-nav-open {
    overflow: hidden;
}

.main-nav {
    display: none;
    position:fixed;
    top:0;
    left:0;
    right: 0;
    z-index:9;
    width: 100%;
    height:100%;
    margin:0 auto;
    overflow-y: auto;
    background:$color-primary-700;
    opacity:0;
    color:$color-white;
    transition: opacity .3s ease-in-out;

    .main-nav-open & {
        display: block;
    }

    &.animate {
        opacity: 1;
    }

    a {
        color:currentColor;
    }

    &:before {
        @include pseudo(block, static, '');
        width:100%;
        height:95px;
        background:$color-primary-700;
        opacity:.9;
    }
}

.main-nav__grid {
    display: grid;
    grid-template-columns:auto auto;
    grid-template-rows: auto auto;
    column-gap:40px;
    row-gap:35px;
    align-items: start;
    padding:0 25px 45px;

    .main-nav__menu-wrap {
        margin:0 -25px;
        grid-column: 1 / 3;
        grid-row-start: 1;
    }

    .main-nav__socials {
        justify-self:end;
    }
}

.main-nav__menu-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap:70px;
    border-bottom:1px solid transparentize($color-neutral-400, .4);
    padding:0 25px 35px;
}

.main-nav__menu {
    display: flex;
    flex-direction: column;
    gap:2vh;

    &:is(ul) {
        @extend %listreset;
    }
}

.main-nav__link {
    display: inline-block;
    font-family:$font-primary-light;
    color:$color-white;

    &:is(a) {
        text-decoration: none;

        &:hover, &:focus-visible {
            text-decoration: none;
        }
    }

    &.main-nav__link--toggle {
        @extend %buttonreset;    
        display: flex;
        align-items: center;
        gap:5px;
        width:100%;
        text-align: left;
    }

    &.main-nav__link--all {
        display: inline-flex;
        align-items: center;
        gap:10px;
        font-family:$font-primary;
        font-size:0.6875rem !important;
        text-transform: uppercase;

        .main-nav__link-icon {
            width:14px;
            height:14px;
            margin-bottom:3px;
        }
    }
}

.main-nav__link-icon {
    width:16px;
    height:16px;
}

.main-nav__sub {
    @extend %listreset;
    display:none;

    .active + & {
        display: flex;
        flex-direction: column;
        gap:20px;
    }
}

// Levels
.main-nav__menu {

    // Level 1
    & > .main-nav__item {
        & > .main-nav__link {
            display: block;
            font-family: $font-primary-thin;
            font-size:#{"clamp(1.75rem, 5vw, 3rem)"};
            letter-spacing:.112px;
            line-height: 1.2;
            transition:color .3s ease-in-out;
            
            &:hover, &:focus-visible, &.active {
                color:$color-primary-200;
            }

            &.main-nav__link--toggle {
                position:relative;

                .main-nav__link-icon {
                    transform: rotate(0deg);
                    transition:transform .3s ease-in-out;
                }
                
                &:after {
                    @include pseudo();
                    top:100%;
                    width: 0;
                    height: 1px;
                    background: transparentize($color-neutral-400, .4);
                    transition:width .3s ease-in-out;
                }

                &.active {
                    padding-bottom:20px;

                    .main-nav__link-icon {
                        transform: rotate(90deg);
                    }

                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// Level 2
.main-nav__sub.main-nav__sub-2 {
    padding-top:20px;

    & > .main-nav__item {
        border-bottom:1px solid transparentize($color-neutral-400, .4);
        padding-bottom:20px;

        & > .main-nav__link,
        .main-nav__subtitle {
            font-family:$font-primary-bold;
            font-size:0.875rem;
            line-height: 1.28;
            text-transform: uppercase;
            color:$color-primary-200;
    
            &.main-nav__link--toggle {
                display:flex;
                width:100%;
    
                .main-nav__link-icon {
                    width:20px;
                    height:20px;
                    margin-left:auto;
                    fill:currentColor;
                    stroke:currentColor;
                }
            }
        }
    }
}

// Level 3
.main-nav__sub.main-nav__sub-3 {
    padding-top:12px;
    padding-left:17px;

    .main-nav__link {
        font-family:$font-primary-thin;
        font-size:1.125rem;
        letter-spacing: 1px;

        &.main-nav__link--toggle {
            
            &:after {
                @include pseudo(block, static, '');
                border:solid currentColor;
                border-width:0 2px 2px 0;
                margin-left:auto;
                padding:3px;
                transform:rotate(45deg);
                transition:transform .3s ease-in-out;
            }
        }
    }
}

// Level 4+
.main-nav__sub.main-nav__sub-4 {
    border-radius: 5px;
    margin-top:20px;
    margin-left:-17px;
    padding:10px 35px;
    background:rgba(22, 98, 204, 0.60);

    .main-nav__link {
        font-size:1rem;
        letter-spacing: 1.5px;
    }
}

// Util Menu
.main-nav__util-menu {
    @extend %listreset;
    display: flex;
    flex-direction: column;
    gap:2vh;
}

.main-nav__util-link {
    font-family:$font-primary-thin;
    line-height:1.25em;
}

// Search
.main-nav__search {
    position:relative;
    max-width:375px;
}

.main-nav__search-label {
    margin-bottom:5px;
    font-size:0.75rem;
    font-weight:$font-primary-thin;
    text-transform: uppercase;
}

input[type="text"].main-nav__search-input {
    width:100%;
    border:0;
    border-bottom:1px solid $color-white;
    padding:10px 25px 10px 0;
    background:none;
    font-family: $font-primary-thin;
    color:currentColor;

    &::placeholder {
        color:currentColor;
    }
}

button.main-nav__search-submit {
    @extend %buttonreset;
    position:absolute;
    bottom:0;
    right:0;
    color:currentColor;
}

.main-nav__search-icon {
    width:18px;
    height:20px;
    fill:currentColor;
}

// Footer Links 
.main-nav__footer-links {
    display:none;
}

.main-nav__footer-menu {
    @extend %listreset;
    display: flex;
    align-items: center;
    gap:40px;
}


.main-nav__footer-item:not(:first-child){
    border-left:1px solid rgba(255, 255, 255, 0.30);
    padding-left:40px;
    line-height:1;
}

.main-nav__footer-link {
    font-family:$font-primary-medium;
    font-size:0.625rem;
    text-transform: uppercase;
    line-height: 1;
}

// Socials
.main-nav__socials {
    flex-direction: column;
}

.main-nav__subtitle {
    display: none;
}

@media(min-width:$bp-l){

    .main-nav {
        padding:0;
        background:transparentize($color-primary-700, .10);
        backdrop-filter: blur(20px);

        &:before {
            position:absolute;
            top:0;
            left:0;
            z-index:1;
        }
    }

    .main-nav__subtitle {
        display: block;
        margin-bottom:18px;
    }

    .main-nav__grid {
        display: grid;
        grid-template-columns: 8% 28% 28% 28% 8%;
        grid-template-rows: auto auto;
        row-gap:50px;
        column-gap:0;
        padding:120px 30px 30px;

        .main-nav__menu-wrap {
            grid-column: 2 / 5;
            grid-row-start: 1;
            padding:35px 40px;
            margin:0;
            border-left:1px solid $color-neutral-400;
            border-bottom:0;
        }

        .main-nav__menu {
            width:28%;
        }

        .main-nav__search {
            grid-column-start:4;
            grid-row-start:1;
            align-self:end;
            margin-bottom:35px;
            margin-left:40px;
        }

        .main-nav__footer-links {
            display: flex;
            align-items: center;
            grid-column:1/5;
            grid-row-start:2;
        }
    
        .main-nav__socials {
            grid-column-start:5;
            grid-row-start:2;
            justify-self: end;
            flex-direction: row;
        }
    }

    .main-nav__util-link {        
        font-size:1.5rem;
    }

    .main-nav__link.main-nav__link--toggle {
        gap:40px;
    }

    .main-nav__link-icon {
        width:24px;
        height:24px;
    }

    .main-nav__sub {
        display: none;

        .main-nav__menu-col > & {
            display: flex;
            flex-direction: column;
            gap:13px;
            padding-left:0;
        }

        .active + & {
            display:none;
        }
    }

    .main-nav__menu > .main-nav__item {
        & > .main-nav__link {
            &.main-nav__link--toggle {
                &:after {
                    display:none;
                }

                &.active {
                    padding-bottom:0;
                    
                    .main-nav__link-icon {
                        transform: none;
                    }
                }
            }
        }
    }
    
    .main-nav__menu-col {
        position:absolute;
        top:0;
        left:0;
        display:none;
        flex-direction: column;
        gap:1rem;
        width:33.333%;
        height: 100%;
        border-left:1px solid $color-neutral-400;
        padding: 35px 40px;
        opacity:0;
        overflow-y: auto;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: #333 #F5F5F5; /* thumb and track color */
        transition:opacity .3s ease-in-out;

        &.active {
            display: block;
        }

        &.animate {
            opacity:1;
        }

        /* Style for the scrollbar track (background) */
        &::-webkit-scrollbar {
            width: 3px; 
            background-color: transparent;
        }

        /* Style for the scrollbar thumb (movable part) */
        &::-webkit-scrollbar-thumb {
            background-color: $color-neutral-400;
        }

        /* Style for the scrollbar thumb on hover */
        &::-webkit-scrollbar-thumb:hover {
            background-color: $color-neutral-400;
        }

    }

    .main-nav__menu-col.main-nav__menu-col--1 {
        left:33.333%;
    }

    .main-nav__menu-col.main-nav__menu-col--2 {
        left: 66.59%;
        padding-bottom:110px;
    }

    // Level 2
    .main-nav__sub.main-nav__sub-2 {
        gap:50px;

        & > .main-nav__item {
            border-bottom:0;        

            & > .main-nav__link {
                margin-bottom:18px;
        
                &.main-nav__link--toggle {
                    display: none;
                }
            }

            &.main-nav__item--landing {
                padding-bottom:0;

                .main-nav__link {
                    margin-bottom: 0;
                }
            }
        }
    }

    // Level 3
    .main-nav__sub.main-nav__sub-3 {
        display: flex;
        flex-direction: column;
        gap:13px;
        padding-top:0;
        padding-left:0;

        & > .main-nav__item > .main-nav__link {
            font-size:1.25rem;
            letter-spacing: 1px;

            &.main-nav__link--toggle {
                position:relative;
                align-items: center;
                gap:13px;
                width:auto;
                padding-bottom:3px;
                margin-left:0;
                transition:margin-left .3s ease-in-out;

                &:before {
                    content:'';
                    position:absolute;
                    top:100%;
                    left:0;
                    width:0;
                    height:1px;
                    background:$color-white;
                    transition:width .3s ease-in-out;
                }

                &:after {
                    opacity:0;
                    margin-bottom:4px;
                    transform:rotate(-45deg);
                    transition:opacity .3s ease-in-out;
                }

                &:hover, &:focus-visible, &.active {
                    &:before {
                        width:100%;
                    }

                    &:after {
                        opacity:1;
                    }
                }

                &.active {
                    margin-left:20px;
                } 
            }
        }
    }

    // Level 4
    .main-nav__sub.main-nav__sub-4 {
        background:none;

        .main-nav__link {
            font-size:1.125rem;
            letter-spacing: 1px;
        }
    }

    .main-nav__search-input {
        font-size:1.5rem;
    }
}

@media(min-height:65.6875rem){
    .main-nav__grid {
        row-gap:85px;
    }

    .main-nav__menu-wrap {
        gap:120px;
    }
}

@media(min-width:120em){
    .main-nav {
        width: 1920px;
    }
}