@use '../abstracts/' as *;

.jump-nav-wrap {
    border-bottom:1px solid $color-tertiary-10;
    overflow-x: auto;

    /* Style for the scrollbar track (background) */
    &::-webkit-scrollbar {
        height: 0; 
        background-color: transparent;
    }

    /* Style for the scrollbar thumb (movable part) */
    &::-webkit-scrollbar-thumb {
        background-color: $color-tertiary-10;
    }

    /* Style for the scrollbar thumb on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: $color-tertiary-10;
    }
}

.jump-nav {
    display: flex;
    gap:#{"clamp(30px, 5vw, 40px)"};
    padding:0 30px;
}

.jump-nav__link {
    position:relative;
    padding:30px 0 20px;
    font-family: $font-primary-light;
    font-size:#{"clamp(1.125rem, 5vw, 1.25rem)"};
    white-space: nowrap;
    color:$color-primary-800;

    &:after {
        @include pseudo();
        bottom:0;
        left:0;
        width:100%;
        height:1px;
        background:$color-primary-500;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &.jump-nav__link--active {
        font-family:$font-primary-bold;
        
        &:after {
            opacity: 1;
        }
    }

    &:hover, &:focus {
        text-decoration: none;

        &:after {
            opacity: 1;
        }
    }
}

@media(min-width:$bp-l){
    .jump-nav__link.jump-nav__link--active {
        font-family: $font-primary-light;
    }
}

@media(min-width:$bp-xl){
    .jump-nav {
        justify-content: center;
    }
}