@use '../abstracts' as *;

.pagination {
	@extend %listreset;
	display: flex;
    flex-wrap:wrap;
    justify-content:center;
    gap:.5rem;
}

.page-link {
    display:block;
    border:none;
    padding:5px 15px;
    transition:background .3s ease-in-out, color .3s ease-in-out;

    &:hover, &:focus-visible {
        text-decoration: underline;
        
        &:hover,
        &:focus-visible {
            color:$color-secondary-400;
        }
    }

    .active & {
        font-family:$font-primary-bold;
    }

    .media-layout.media-layout--darkblue & {
        color:#838A95;

        &:hover,
        &:focus-visible {
            color:$color-secondary-400;
        }
    }
}