@use '../../abstracts/' as *;

.card.card--skyscraper {
    position: relative;
    z-index:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height:clamp(640px, 57vw, 1110px);
    border-radius: 0;
    margin-bottom: 8px;
    padding:clamp(28px, 2.1vw, 38px) clamp(34px, 2.1vw, 40px);
    background-color: $color-neutral-600;
    box-shadow: none;
    color: $color-white;

    &:before {
        @include pseudo();
        top:0;
        left:0;
        z-index:0;
        width: 100%;
        height: 100%;
        background: linear-gradient(179.48deg, rgba(9, 16, 112, 0) 25.98%, rgba(6, 20, 37, 0.7) 99.6%);
    }
    
    .card__subtitle{
        position: absolute;
        top: 35px;
        color: $color-primary-700;
        font-size: clamp(1rem, 1.1vw, 1.375rem);
        font-family:$font-primary-bold;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 36px;
        letter-spacing: 0.26px;
        word-wrap: break-word;
        &:before{
            @include pseudo();
            position: absolute;
            top: 44%;
            left: -40px;
            width: 27px;
            height: 2px;
            background-color: $color-white;
        }
    }

    .card__media {
        position:absolute;
        top:0;
        left:0;
        z-index:-1;
        width:100%;
        height:100%;
        background-size: cover;
        background-position: center;
    }

    .card__body {
        position:relative;
        z-index:1;
        color:$color-white;
    }

    .card__title {
        font-size: clamp(2.25rem,2.29vw,2.8rem);
        margin-bottom: 2rem;
    }

    .card__summary {
        font-size:clamp(1rem, 1.1vw, 1.25rem);
        line-height: 1.7;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 4;
    }

    .card__link {
        margin-top:30px;
    }

    .button.button--arrow {
        .arrow {
            background: rgba(242, 241, 240, 0.3);
        }
    }
}

@media(min-width:$bp-m){
    .card-skyscraper-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap:clamp(15px, 2vw, 40px);
        padding-bottom: 6%;
    }
}


@media(min-width:$bp-mw-max){
    .card-skyscraper-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding-bottom: 12%;
    }

     .card.card--skyscraper {
        margin-bottom: 0;

        .card__subtitle {
            &:before{
                left: -50px;
                top: 44%;
                width: 34px;
            }
        }
        &:nth-child(3n + 2) {
            margin-top: 20%;
            margin-bottom: -20%;
          }
          
         &:nth-child(3n) {
            margin-top: 30%;
            margin-bottom: -30%;
          }

          .card__summary {
            font-size:1.25rem;
            line-height: 1.7;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}