@use '../../abstracts/' as *;

.card.card-blue-image-whiteBg {
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    padding: 0 0 3rem;
    background: none;
    
    .card__title {
        text-align: center;
        line-height: 1.2;
        padding: 56px 32px;
    }

    .card__body,
    .card__body-inner {
        display: flex;
        flex-direction: column;
        gap:32px;
        padding-right: 24px;
    }

    .card__body-inner {
        padding-left:24px;
    }

    .card__img {
        width:100%;
    }

    .card__summary {
        position:relative;
        border-bottom-right-radius: 50px;
        margin-top: -56px;
        padding:56px 40px;
        background:$color-secondary-300;
        font-family: $font-primary-bold;
        font-size:#{"clamp(1.5rem, 5vw, 1.875rem)"};
        line-height: 1.3;
        color:$color-white;
    }

    .card__content {
        margin:0;
        font-size:#{"clamp(1rem, 5vw, 1.25rem)"};
        font-family: $font-primary-light;
        line-height: 1.75;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }

    &:after {
        display:none;
    }
}

@media(min-width:$bp-l){
    .card.card-blue-image-whiteBg {
        position:relative;

        .card__main {
            position: relative;
            display: grid;
            align-items: start;
            gap: 5%;
            grid-template-columns: 40% 1fr;
            padding: 8% 0 0 0;
        }

        .card__media {
            position: relative;
            
            &:before {
                @include pseudo();
                right: -35px;
                bottom: -40px;
                z-index: 1;
                display: block;
                height: 114px;
                width: 108px;
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 30px 30px;
            }
        }

        .card__media-inner {
            position: relative;
            padding-top:80%;
            overflow: hidden;

            .card__img {
                position:absolute;
                top:0;
                left:0;
                width: auto;
                min-width:100%;
                max-width:none;
                height:100%;
                object-fit: cover;
            }
        }

        .card__body,
        .card__body-inner {
            padding-right: 0;
            padding-left:0;
            gap:64px;
        }

        .card__summary {
            z-index: 2;
            max-width:945px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 50px;
            margin-left: -20%;
            margin-top: -8%;
            padding: 9%;
            line-height: 1.6;
        }

        .card__content {
            max-width:666px;
            margin-right:0;
            font-size:1.25rem;
            line-height:1.7;
        }
    }
}

@media(min-width:$bp-xxxl){
    .card.card-blue-image-whiteBg {

        .card__main {
            grid-template-columns: 727px 945px;
            padding: 5% 4.5% 0 0;
            gap:150px;
        }

        .card__body {
            gap:90px;
        }
    }
}
