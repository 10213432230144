@use '../abstracts' as *;

.site-footer {
    padding:40px 24px 38px;
    background: #000;
    color:$color-white;

    a {
        color:currentColor;
    }
}

.footer-top {
    display:grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap:35px;

    .footer-col {
        flex-grow:1;

        &:empty() {
            display:none;
        }
    }
    
    .footer-col--logo {
        width:100%;
        grid-column-start:1;
        grid-column-end:3;
    }
}

.footer-links__menu {
    display: flex;
    flex-direction: column;
    gap:12px;
}

.footer-links__link {
    font-family:$font-primary-light;
    font-size:1.0625rem;
    letter-spacing: 1px;
}

.socials {
    display: flex;
    gap:16px;
    margin:10px 0 0 0;
    padding:0;
    list-style-type: none;
    color:#CBD0D5;
}

.footer-bottom {
    display: grid;
    gap:40px;
    margin-top:40px;
}

.footer-bottom__links {
    display: flex;
    flex-wrap:wrap;
    gap:15px;
    margin:0;
    padding:0;
    list-style-type: none;
}

.footer-bottom__links-item {
    line-height:0;

    &:not(:first-child){
        border-left:1px solid rgba(255, 255, 255, 0.30);
        padding-left:15px;
    }
}

.copyright,
.footer-bottom__links-link {
    font-family:$font-primary-medium;
    font-size:0.625rem;
    letter-spacing: .5px;
    text-transform: uppercase;
    line-height: 1.25;
}

@media(min-width:$bp-m){
    .footer-top {
        display: flex;
        flex-wrap:wrap;
    }

    .socials {
        margin-top:0;
        flex-direction: column;
    }
}

@media(min-width:$bp-l){
    .site-footer {
        padding:56px 56px 38px;
    }

    .footer-top {
        display: grid;
        grid-template-columns: 28% 1fr 1fr 1fr 1fr max-content;
        gap:7vw;

        .footer-col--logo {
            grid-column-end:auto;
        }
    }

    .footer-bottom {
        display: grid;
        grid-template-columns: 28% 1fr;
        align-items: center;
        gap:7vw;
        
        .footer-col--copyright {
            grid-column-start:1;
            grid-row-start:1;
        }

        .footer-col:not(.footer-col--copyright){
            grid-column-start:2;
        }
    }
}

@media(min-width:$bp-xxxl){
    .footer-bottom__links {
        gap:40px;
    }

    .footer-bottom__links-item:not(:first-child){
        padding-left:40px;
    }
}