@use '../../abstracts/' as *;
@use 'sass:math';

.card--infoScroll-wrapper {
    overflow: visible;

    .card__Sectiontitle {
        text-align: center;
    }
}

.card.card--infoScroll {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 2;
    background: none;
    overflow: visible;

    &:after {
        display: none;
    }

    .card__media {
        margin-bottom:20px;
    }

    .card__title {
        z-index: 2;
        position: relative;
        font-size:#{"clamp(1.875rem, 3vw, 2.5rem)"};
        line-height: 1.2;
        color:$color-primary-700;
    }

    .card__body {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap:20px;
    }

    .card__summary {
        font-family: $font-primary-light;
        font-size: clamp(1rem, 1vw, 1.375rem);
    }

    .card__content {
        font-family: $font-primary-light;

        &>*:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .card__link {
        width: 100%;
    }
}

.card-info-list {
    display: flex;
    flex-direction: column;
    gap:60px;
}

@media(min-width:$bp-mw) {
    .card--infoScroll-wrapper {
        padding-bottom: 50px;

        .card__Sectiontitle {
            text-align: left;
        }
    }

    .card__SectionWrapper {
        display:grid;
        grid-template-columns: 41.8% 1fr;
        gap:83px;

        .card__Sectiontitle {
            grid-column:2;
        }
    }

    .card.card--infoScroll {
        display:grid;
        grid-template-columns: 41.8% 1fr;
        gap:83px;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        position: relative;
        z-index: 2;

        &:after {
            display: none;
        }

        .card__media {
            position: relative;
            opacity: 0;
            margin-bottom:0;
            transition:opacity .3s ease-in-out;

            .card__img {
                position:absolute;
                top:0;
                left:0;
            }
        }

        .card__title {
            z-index: 2;
            position: relative;
        }

        .card__body {
            display: flex;
            flex-direction: column;
            gap:40px;
            text-align: left;

            &:after {
                content: "";
                display: block;
                height: 2px;
                width: 50px;
                background: $color-neutral-500;
            }
        }

        .card__content {
            font-family: $font-primary-light;

            &>*:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        &.active {
            .card__media {
                opacity: 1;
                background-position: 100% 50%;
                filter: blur(0);
            }

            &.static {
                .card__media {
                    opacity: 1;
                    filter: none;
                }
            }
        }
    }

    .card-info-list {
        gap:60px;
        
        .card.card--infoScroll:first-child {
            .card__media .card__img {
                top:-40px;
            }

            .card__body {
                padding-top:80px;
            }
        }
    }
}

@media(min-width:$bp-xxl) {
    .card--infoScroll-wrapper {
        padding-bottom: 5%;
    }
}