@use '../../abstracts/' as *;

.card.card--numbered {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    font-family: $font-primary-light;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0.012em;
    text-align: left;
    color: $color-primary-800;
    background: none;

    .card__number {
        flex-shrink:0;
    }

    .card__body {
        flex-grow:1;
    }

    .card__title {
        position: relative;
        display: flex;
        align-items: flex-start;
        gap:30px;
        margin-top:0;
        padding-top:0;
        font-family: $font-primary-bold;
        font-size: 1.875rem;
        line-height: 36px;
        letter-spacing: 0.004em;
        text-align: left;
        color:$color-primary-700;
        font-weight: 700;
        cursor: pointer;

        &:after {
            content: '+';
            display: block;
            margin-left:auto;
            font-size: 3.125rem;
            color: $color-primary-700;
        }


        &.active:after {
            content: '-';
        }
    }

    .card__inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap:1.25rem;
        border-top: 1px solid $color-neutral-500;
        padding: 3rem 2rem;
    }

    .card__summary-wrap {
        display: none;
    }

    .card__summary {
        margin-top: 1rem;
    }

    .card__number {
        font-family: $font-primary-thin;
        font-size: 40px;
        font-weight: 200;
        line-height: 1;
        text-align: left;
        color: $color-primary-300;
    }
}

@media(min-width:$bp-m){
    .card.card--numbered {
        .card__inner{
            display: grid;
            grid-template-columns: 15% 1fr;
            gap:3.5rem;
            padding: 3.5rem 5rem 3.5rem 0;
            border-bottom: 1px solid $color-primary-300;
            border-top: 0;
        }

        .card__number {
            font-size: 6.25rem;
        }

        .card__title {
            margin-bottom: 1.5rem;
            font-size: 2.5rem;
            line-height: 1.3;
            letter-spacing: 0;
            cursor: default;

            &:after {
                display: none;
            }
        }

        .card__summary-wrap {
            display: block !important;
        }

        .card__summary {
            margin-top: 1.5rem;
        }
    }
}