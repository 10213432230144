@use '../../abstracts/' as *;
@use 'sass:math';

.card.card--circular-image {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    overflow: visible;
    position: relative;
    background:none;

    .card__media-wrapper{
        position: relative;
        width: 80%;
        margin: 0 auto;
    }


    .card__media {
        background:no-repeat center/cover;
        position: relative;
        width: 100%;
        z-index: 1;
        margin: 0 auto;
        margin-bottom: -14rem;
        z-index: 2;
        left: 0;
        &::after{
            content: "";
            display: block;
            padding-top: 110%;
          }
         
    }
   
    .card__title {
        z-index: 2;
        position: relative;
        font-size:#{"clamp(1.875rem, 5vw, 2.5rem)"};
        padding:2rem 0 1rem;
        color: $color-white;
        font-family: $font-primary-bold;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.12px;
        word-wrap: break-word
    }

    .card__body {
        position: relative;
        padding: 14rem 2rem 3rem;
        position: relative;
        z-index: 1;
        background: linear-gradient(182deg, #3492EA 0%, #000F9F 100%);
        color: $color-white;
        border-bottom-right-radius: 50px;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }

    .card__summary {
        font-size:#{"clamp(1rem, 3vw, 1.25rem)"};
        font-family: $font-primary-thin;
    }
}

@media(min-width:$bp-mw){
    .card.card--circular-image {
        padding-top: 200px;
        position:relative;
        display: flex;
        grid-template-columns: 40% 1fr;
        align-items: flex-end;
        &::before{
            height: 93%;
            right: 0;
            top: 7%;
            width: 80%;
            z-index: 1;
        }
        .card__media-wrapper{
            position: relative;
            height: 200%;
            margin: -30% 0 0;
            width: 200%;
        }
        .card__media{
            height: 100%;
            margin: 0;
            width: 100%;
            min-height: 660px;
            min-width: 540px;
            background: no-repeat center/contain;
            bottom: -50px;
        }
        .card__body {
            background: linear-gradient(182deg,#3492ea,#000f9f);
            border-bottom-right-radius: 50px;
            color: #fff;
            padding: 4rem 5rem 5rem;
            position: relative;
            z-index: 1;
            margin-left: -20%;
            padding-left: 27%;
        }


        .card__title {
            position: relative;
            text-align: left;
            font-size: 2.7rem;
            padding: 2rem 0 1rem;
        }
    }
}

