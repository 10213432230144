@use '../../abstracts/' as *;

.hero.hero--image-right {
    position: relative;
    background: none;
    padding: 87px 24px 0;
    overflow: hidden;

    &:before {
        display: none;
    }

    .hero__img {
        position: absolute;
        top: 0;
        left: 0;
        z-index:1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero__body {
        position: relative;
        z-index:4;
        display: grid;
        gap:32px;
        max-width:none;
    }

    .hero__title {
        max-width:none;
        font-size:#{"clamp(3.25rem, 10vw, 5rem)"};
        color:$color-primary-800;
    }

    .hero__content {
        position: relative;
        max-width:700px;
        padding:40px 40px 30px 50px;
        background:$color-white;

        &:before {
            @include pseudo();
            top:50px;
            left:-25px;
            width:50px;
            height:2px;
            background:$color-primary-400;
        }
    }

    .hero__summary {
        font-family: $font-primary-light;
        font-size:#{"clamp(1.125rem, 5vw 1.75rem)"};
        line-height: 1.5;
        color:$color-primary-800;
    }
  }

@media(min-width:$bp-l){
    .hero.hero--image-right {
        padding:120px 0 130px;

        &:before {
            @include pseudo();
            bottom: -2px;
            left: -2px;
            z-index: 3;
            width: 100%;
            height: 100%;
            background-image:url($img-path+'hero-curve-left.png');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        
        .hero__content {
            display: flex;
            flex-direction: column;
            padding:72px 72px 50px 96px;

            &:before {
                top:90px;
                left:-50px;
                width:111px;
            }

            .hero__link {
                margin-top:86px;
                justify-self: flex-end;
            }
        }
    }
}

@media(min-width:$bp-xxl){
    .hero.hero--image-right {
        
        .hero__content {
            display: flex;
            flex-direction: column;
            padding:72px 72px 50px 96px;

            .hero__link {
                margin-top:86px;
                justify-self: flex-end;
            }
        }
    }
}