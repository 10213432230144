@use "../../abstracts/" as *;

.card-slider.card-slider--blue {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background: #775EE6;
  padding: 0;

  .card {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 0;
    background:none;
    box-shadow: none;
    opacity: 0 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  .card__media {
    position:relative;
    width: 100%;
    height:315px;
    overflow: hidden;

    .card__img {
      position: absolute;
      top: 0;
      left: 0;
      min-width:100%;
      height:100%;
      max-width:none;
      object-fit: cover;
    }
  }

  .card__body {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top:-60px;
    margin-right:20px;
    padding: 40px 40px 56px;
    background: $color-black;
    font-family: $font-primary-light;
    color: $color-white;
  }

  .card__title {
    padding: 0;
    font-size: clamp(2.25rem, 3.6vw, 5rem);
  }

  .card__summary {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0;

    &::before {
      @include pseudo(block, static, '');
      min-width: 20px;
      width: 50px;
      height: 2px;
      margin: 20px 0;
      background: #F5F5F5;
    }
  }

  .card__link {
    margin-top: 30px;

    .button {
      font-size:0.8rem;
      letter-spacing: 3.2px;
      font-weight: 600;
    }
  }

  .card-slider__buttons {
    display: flex;
    gap: 20px;

  }

  .card-slider__button {
    @extend %buttonreset;
    position: static;
    width: 22px;
    height: 22px;
    color: $color-white;

    &:after {
      display: none;
    }

    .card-slider__button-icon {
      width: 100%;
      height: 100%;
    }
  }
}

.card-slider__navigation {
  display: flex;
  gap:32px;
  align-items: center;
  width:100%;
  margin-top:14px;
}

.card-slider__pagination {
  position: static;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  opacity: 1;
  margin-top:0;

  .swiper-pagination-bullet {
    height: 2px;
    width: 16%;
    border-radius: 0;
    margin: 0;
    opacity: 1;
    background: #747d8e;
    font-size: 0;
    color: transparent;

    &.swiper-pagination-bullet-active {
      background: $color-white;
    }
  }
}

.card-slider__buttons {
  display: flex;
  gap: 30px;
}

@media(min-width:$bp-l) {
  .card-slider.card-slider--blue {

    &:before {
      display: none;
    }

    .card {
      position: relative;
      width: 100%;
      padding: 120px 0 130px;

      &:before {
        @include pseudo();
        top: auto;
        bottom: -2px;
        left: -2px;
        z-index: 3;
        width: 100%;
        height: 100%;
        background: none;
        background-image: url($img-path + "curve-left-blue.png");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .card__media {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height:100%;
    }

    .card__body {
      position: relative;
      z-index: 4;
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 57.25%;
      max-width: 824px;
      margin-top:0;
      margin-right:0;
      padding: 48px;
      background: $color-black;
      color: $color-white;
    }

    .card__summary {
      gap: 1.5rem;

      &::before {
        min-width: 50px;
        width: 50px;
      }
    }

    .card__link {
      margin-top: 15px;
    }

    .card-slider__button {
      height: 40px;
      width: 47px;
    }

    .card-slider__navigation {
      position:absolute;
      bottom:70px;
      width: 57.25%;
      max-width: 824px;
    }

    .card-slider__pagination {
      gap: 50px;
    }

    .swiper-pagination-bullet {
      background: hsla(0, 0%, 100%, .5);
      width: 10px;
      height: 10px;
      margin: 0;
      border-radius: 50%;
      transition: background .3s ease-in-out;

      &.swiper-pagination-bullet-active {
        background: $color-white;
      }
    }
  }
}

@media(min-width:$bp-xl) {
  .card-slider.card-slider--blue {
    .card__body {
      padding: 72px;
    }
  }
}