@use '../abstracts' as *;

/* Maps & Variables
================================================================ */
$textAlignments:left, right, center;

$fontWeights: (
    "thin": $font-primary-thin,
    "light": $font-primary-light,
    "normal": $font-primary,
    "medium": $font-primary-medium,
    "bold": $font-primary-bold
);

$textCase:(
    "upper":uppercase,
    "lower":lowercase,
    "cap":capitalized
);

$displays:(
    "none": none,
    "block":block,
    "inline-block":inline-block,
    "inline":inline,
    "flex":flex,
    "inline-flex":inline-flex
);

// Uncomment if you want to create spacing helpers
$spacer:16px;
$sides: (
    all:"",
    vertical:"y",
    horizontal:"x",
    top:"t", 
    right:"r", 
    bottom:"b", 
    left:"l"
);
$spacers: (
  0: 0,
  1: $spacer * .25,  
  2: $spacer * .5,   
  3: $spacer * .75,  
  4: $spacer * 1,    
  5: $spacer * 1.25, 
  6: $spacer * 1.5,  
  8: $spacer * 2,    
  10: $spacer * 2.5, 
  12: $spacer * 3,   
  16: $spacer * 4,   
  20: $spacer * 5,       
  24: $spacer * 6,   
  32: $spacer * 8,
  40: $spacer * 10,
  48: $spacer * 12,
  56: $spacer * 14,
  64: $spacer * 16,
  72: $spacer * 18
);

$gradients: (
    'primary-400-300': (
        'direction': '150deg',
        'colors': '#{$color-primary-400} 0%, #{$color-primary-300} 100%'
    ),
    'primary-300-500-700': (
        'direction': '166deg',
        'colors': '#{$color-primary-300} 100%'
    ),
    'primary-500-700': (
        'direction': '150deg',
        'colors': '#{$color-primary-500} 0%, #{$color-primary-700} 100%'
    ),
    'secondary-400-300': (
        'direction': '0',
        'colors': '#{$color-secondary-400} 0%, #{$color-secondary-700} 86%'
    ),
    'secondary-200-400-600': (
        'direction': '180deg',
        'colors': '#{$color-secondary-200} 6%, #{$color-secondary-400} 58%, #{$color-secondary-600} 100%'
    ),
    'secondary-500-700': (
        'direction': '180deg',
        'colors': '#{$color-secondary-100} 0%, #{$color-secondary-300} 47%, #{$color-secondary-500} 100%'
    ),
    'secondary-400-300-transp': (
        'direction': '180deg',
        'colors': '#{$color-secondary-800} 0%, #{transparentize($color-secondary-500, .15)} 49%, rgba(144, 180, 38, 0) 100%'
    ),
    'secondary-100-300-500': (
        'direction': '180deg',
        'colors': '#{$color-secondary-100} 0%, #{$color-secondary-300} 47%, #{$color-secondary-500} 100%'
    ),
    'secondary-600': (
        'direction': '0',
        'colors': '#{$color-tertiary-6} 0%, #{$color-secondary-600} 100%'
    ),
    'duotone-1': (
        'direction': '180deg',
        'colors': '#{$color-primary-50} 0%, #{$color-secondary-50} 100%'
    ),
    'duotone-2': (
        'direction': '180deg',
        'colors': '#{$color-tertiary-1} 0%, #{$color-secondary-50} 100%'
    ),
    'duotone-3': (
        'direction': '180deg',
        'colors': '#{$color-tertiary-2} 0%, #{$color-tertiary-7} 100%'
    ),
    'duotone-4': (
        'direction': '180deg',
        'colors': '#{$color-tertiary-1} 0%, #{$color-tertiary-4} 100%'
    ),
    'duotone-5': (
        'direction': '0deg',
        'colors': '#{$color-tertiary-5} 0%, #{$color-tertiary-1} 100%'
    ),
    'duotone-6': (
        'direction': '180deg',
        'colors': '#{$color-tertiary-3} 0%, #{$color-tertiary-5} 100%, #{$color-tertiary-5} 100%'
    ),
    'photo-1': (
        'direction': '180deg',
        'colors': 'rgba(9, 16, 112, 0) 16%, rgba(6, 12, 70, 0.70) 100%'
    ),
    'photo-2': (
        'direction': '135deg',
        'colors': 'rgba(6, 12, 70, 0) 0%, rgba(6, 12, 70, 0.80) 100%'
    )
);

@mixin generate-gradients() {
    @each $gradient, $gradientValue in $gradients {
        .gradient-#{$gradient} {
            background: linear-gradient(
                #{map-get(map-get($gradients, $gradient), 'direction')},
                #{map-get(map-get($gradients, $gradient), 'colors')}
            );
        }
    }
}

@mixin generate-spacing-classes($bpAbbv: ''){
    @each $side, $sideValue in $sides {
        @each $space, $spaceValue in $spacers {
            @if ($sideValue == "") {
                .p#{$sideValue}-#{$bpAbbv}#{$space} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin: $spaceValue;
                }
                .-m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin: -$spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$bpAbbv}#{$space} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }

                .-m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-top: -$spaceValue;
                    margin-bottom: -$spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$bpAbbv}#{$space} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
                .-m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-left: -$spaceValue;
                    margin-right: -$spaceValue;
                }
            } @else {
                .p#{$sideValue}-#{$bpAbbv}#{$space} {
                    padding-#{$side}: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-#{$side}: $spaceValue;
                }
                .-m#{$sideValue}-#{$bpAbbv}#{$space} {
                    margin-#{$side}: -$spaceValue;
                }
            }
        }
    }
}


/* Text Helpers
================================================================ */
@each $fontWeight, $weightValue in $fontWeights {
    // .text-normal { font-family:HelveticaNowDisplayRegular, sans-serif; }
    .text-#{$fontWeight} {
        font-family:$weightValue;
    }
}

@each $alignment in $textAlignments {
    // .text-left { text-align:left; }
    .text-#{$alignment} {
        text-align:$alignment;
    }
}

@mixin generate-display-classes($bpAbbv: '') {
    @each $display, $displayVal in $displays {
        .d#{$bpAbbv}-#{$display} {
            display: $displayVal;
        }
    }
}

@each $case, $caseValue in $textCase {
    // .text-upper { text-transform:uppercase; }
    .text-#{$case} {
        text-transform:$caseValue;
    }
}

@each $color, $colorValue in $colors {
    // .color-primary { color:$color-primary }
    .color-#{$color} {
        color:$colorValue;
    }

    // .bg-color-primary-400 { background-color:$color-primary-400 }
    .bg-color-#{$color} {
        background-color:$colorValue;
    }
}

/* Element Helpers
================================================================ */
.clearfix {
    @extend %clearfix;
}

.list-unstyled {
    @extend %listreset;
}

.tl-rounded {
    border-top-left-radius: 50px;
}

.tr-rounded {
    border-top-right-radius: 50px;
}

.bl-rounded {
    border-bottom-left-radius: 50px;
}

.br-rounded {
    border-bottom-right-radius: 50px;
}

.w-full {
    width:100%;
}

.align-self-flex-start {
    align-self:flex-end;
}

.align-self-flex-end {
    align-self:flex-end;
}

.align-self-start {
    align-self: start;
}

.align-self-center {
    align-self:center;
}

.align-self-end {
    align-self:end;
}

[class*="-mb"] {
    position: relative;
    z-index: 1;
}

.bg-center-cover {
    --sf-background-position:center;
    --sf-background-size:100% auto;
    background-position: center;
    background-size: 100% auto;
}

@include generate-gradients();
@include generate-spacing-classes();
@include generate-display-classes();

/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-spacing-classes('#{$bpAbbv}-');
        @include generate-display-classes('-#{$bpAbbv}');

        @each $alignment in $textAlignments {
            // .text-left { text-align:left; }
            .text-#{$bpAbbv}-#{$alignment} {
                text-align:$alignment;
            }
        }
    }
}