@use '../../abstracts/' as *;

.card-slider.card-slider--white {
    display: flex;
    flex-direction: column;
    gap:32px;
    padding:0 24px;

    .card-slider__title {
        font-size:#{"clamp(2.75rem, 4.2vw, 5rem)"};
    }

    .card-slider__media {
        margin-left:-24px;
        margin-right:-24px;
    }

    .card-slider__img {
        width:100%;
    }

    .card {
        border-radius: 0;
        padding:0;
        box-shadow: none;
        background:none;
        color: unset;

        &.swiper-slide {
            opacity: 0 !important;
        }

        &.swiper-slide-active {
            opacity: 1 !important;
            overflow: visible;
        }
    }

    &:after {
        display:none;
    }

    .card__title {
        margin:0;
        padding:0;
        font-size: #{"clamp(1.25rem, 3vw, 1.625rem)"};
        line-height: 1.3;
        color:$color-primary-700;
    }
    
    .card__body {
        position: relative;
        z-index: 1;
        display:flex;
        flex-direction: column;
        gap:24px;
        border-radius:0 0 50px 0;
        padding: 56px 36px;
        background:$color-white;
    }

    .card__content {
        font-family: $font-primary-light;
        font-size:#{"clamp(1rem, 4vw, 1.25rem)"};
        line-height: 1.75;
        color:$color-primary-800;
    }

    & > .card,
    .card-slider__comp {
        position:relative;
        z-index:2;
        margin-top:-70px;   
    }
}

/* Slider Navigation */
.card-slider.card-slider--white {
    
    .card-slider__nav {
        --swiper-pagination-bullet-inactive-opacity: .3;
        position: relative;
        display: flex;
        align-items: center;
        gap:10px;
        margin-top:60px;
    }
    
    .card-slider__buttons {
        display: flex;
        gap:20px;
    }
        
    .card-slider__button {
        @extend %buttonreset;
        position:static;
        width:22px;
        height:22px;
        color:#5A5D80;
    
        &:after {
            display: none;
        }
    
        .card-slider__button-icon {
            width:100%;
            height: 100%;
        }
    }
}

/* Slider Pager */
.card-slider__pager {
    --swiper-pagination-bullet-height:2px;
    --swiper-pagination-bullet-size:16%;
    --swiper-pagination-color:#000F9F;
    --swiper-pagination-opacity:1;
    --swiper-pagination-bullet-border-radius:0;
    --swiper-pagination-bullet-horizontal-gap:0;
    --swiper-pagination-inactive-color:#A2AAAD;

    position: static;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    opacity: 1;

    .swiper-pagination-bullet {
        font-size: 0;
        color: transparent;
        transition: background .3s ease-in-out;
    }
}

@media(min-width:$bp-mw){
    .card-slider.card-slider--white {
        position: relative;
        z-index:1;
        display: grid;
        grid-template-columns: 56.6% 43.4%;
        row-gap:55px;
        column-gap:0;
        align-items: start;
        max-width:1670px;
        margin-left:auto;
        margin-right: 0;
        padding:0;

        & > .card,
        .card-slider__comp {
            position:relative;
            z-index: -1;
            right:-10%;
            grid-column:1;
            margin-top:0;
        }

        .card-slider__title {
            grid-column:1;
            grid-row-start:1;
            margin-right:17%;
            text-align: right;
            line-height:1.2;
        }

        .card-slider__media {
            position: relative;
            grid-column:2;
            grid-row:1/5;
            margin:7% 0 0 0;
            padding-top:79.9%;

            .card-slider__img {
                position: absolute;
                top:0;
                min-width:100%;
                max-width: none;
                height: 100%;
                object-fit: cover;
            }

            &:before {
                @include pseudo();
                top: -6%;
                left: -6%;
                z-index:1;
                width: 16%;
                height: 18%;
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 28% 28%;
            }
        }

        .card__body {
            position:relative;
            gap:38px;
            margin: 0;
            padding: 9.4% 18% 14.8% 10%;
            &:before {
                border-bottom: 2px solid #9eba27;
                content: "";
                left: -40px;
                position: absolute;
                top: 5rem;
                width: 80px;
                z-index: 1;
            }
        }

        .card__content {
            line-height:1.7;
        }

        .button {
            padding-left:56px;
            padding-right:56px;
        }
    
    }

    .card-slider.card-slider--white {
        .card-slider__nav {
            position: absolute;
            left: 10%;
            right:18%;
            bottom: 10.6%;
            display: flex;
            align-items: center;
            gap:10px;
            margin-top:0;
        }
    }

    .card-slider__pager {
        --swiper-pagination-bullet-height:10px;
        --swiper-pagination-bullet-size:10px;
        --swiper-pagination-color:#525C70;
        --swiper-pagination-bullet-border-radius:50%;
        --swiper-pagination-inactive-color:#525C70;
        --swiper-pagination-inactive-opacity:0.3;
        
        gap:30px;
    }
}

@media(min-width:$bp-xxxl){
    .card-slider.card-slider--white {

        & > .card,
        .card-slider__comp {
            right:-100px;
        }

        .card__title {
            margin-right:160px;
        }

        .card__body {
            padding:86px 167px 136px 92px;
        }

        .card-slider__nav {
            right:167px;
            bottom:56px;
        }
    }
}