@use '../../abstracts/' as *;
@use 'sass:math';

.card.card--green-gradient {
    position: relative;
    z-index:0;
    width: 100%;
    border-radius: 0;
    padding: 0;
    background: none;
    box-shadow: none;
    overflow: visible;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        background: linear-gradient(180deg,#658d30,#658d30 10%,#cfde2c 70%,hsla(0,0%,50%,.04) 100%,hsla(0,0%,50%,.04));
        height: 90%;
        z-index: 1;
    }
    
    .card__body {
        padding:0 1.875rem;
        margin: 0;
        color: $color-white;
        text-align: center;
    }

    .card__body-inner {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap:20px;
        padding-top:56px;
    }

    .card__action {
        margin-top:12px;
    }
    
    .card__media {
        background:no-repeat center/cover;
        position: relative;
        width: 100%;
        &::after{
            content: "";
            display: block;
            padding-top: math.percentage(math.div(655,917));
          }
    }

    .card__subheading {
        font-size:#{"clamp(1.875rem, 5vw, 2.5rem)"};
        color:$color-primary-700;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }


}

@media(min-width:$bp-mw){
    .card.card--green-gradient {
        padding: 0;
        height: math.percentage(math.div(920,1728));
        background: $color-primary-500;
        
        &::before{
            display: none;
        }

        .card__body {
            display: inline-grid;
            align-items: start;
            gap: 1%;
            grid-template-columns: 50% 1fr;
            padding: 13.8% 0 11%;
            text-align: left;
        }

        .card__body-inner {
            gap:56px;
        }

        .card__action {
            margin-top:0;
        }

        .card__media {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background:no-repeat center top/cover;

            &::after {
                display: none;
            }
        }
    }
}

@media(min-width:$bp-xxl){
    .card.card--green-gradient {
        .card__body {
            grid-template-columns: 45% 1fr;
        }
    }
}
