@use '../../abstracts/' as *;

@keyframes slide-in {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;

  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50%);
  }
}

.alert {
  display: none;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  padding: .7rem 0;
  background-color: $color-primary-500;
  color: $color-white;
  z-index: 1000;

  &.alert--black {
    background-color: $color-secondary-300;
    color: $color-black;

    .button {
      border-color: $color-black;
      color: $color-black;
    }

    .alert-dismiss {
      path {
        stroke-width: 3px;
        stroke: $color-black;
      }

      &:hover {
        path {
          stroke: rgba($color-black, 0.75);
        }
      }
    }
  }

  &.alert--white {
    background-color: $color-primary-500;
    color: $color-white;
  }

  &.show {
    position: static;
    display: block;
    opacity: 1;
    transition: 1s;
    animation: slide-in 0.5s, fade-in 0.5s;
  }
  
  &.remove {
    transition: 1s;
    animation: slide-out 0.5s, fade-out 0.5s;
  }

  .button {
    margin: 0 1rem;
  }
}

.alert__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 2.5rem;
}

.alert__content {
  flex-grow: 1;
  font-size: 0.9375rem;

  *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.alert__dismiss {
  @extend %buttonreset;
  display: inline-flex;
  padding: 10px;
  flex-shrink: 0;
  cursor: pointer;

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  path {
    stroke-width: 3px;
    stroke: $color-white;
  }

  &:hover {
    path {
      stroke: rgba($color-white, 0.75);
    }
  }
}

.alert-slider {
  --swiper-navigation-size:20px;
  --swiper-navigation-color:#{$color-white};

  [class*="swiper-button"] {
    display: none;
  }

  &.swiper-initialized {
    [class*="swiper-button"] {
      display: block;
    }
  }
}