@use '../../abstracts/' as *;


.testimonial {
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.testimonial__media {
  height: 100%;
  margin: auto;
  position: relative;
}

.testimonial__icon {
  position: absolute;
  top: 3em;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: color-primary-100;
}

.testimonial__content {
  position: relative;
  padding: 100px 0;
}

.testimonial__quote {
  display: flex;
  flex-direction: column;
  gap:32px;
  border-left: 0;
  text-align: center;
  color:$color-primary-800;
}

.testimonial__quote-text {
  margin:0;
  font-family:$font-primary-bold;
  font-size:#{"clamp(1.5rem, 3vw, 2.25rem)"};
  line-height:1.3;
}

.testimonial__cite {
  display: flex;
  flex-direction: column;
  gap:7px;
  font-size: 14px;
  font-style: normal;
}

.testimonial__name {
  position: relative;
  letter-spacing: 0.14px;
  font-family: $font-primary-thin;
  font-size: #{"clamp(1.875rem, 2vw, 2.25rem)"};
  line-height: 1.2;
  color: $color-white;
}

.testimonial__position {
  font-size: #{"clamp(0.75rem, 1vw, 0.875rem)"};
  font-family: $font-primary-bold;
  letter-spacing: 1.68px;
  word-wrap: break-word;
  color: $color-white;
}

.testimonial__nav {
  --swiper-pagination-bullet-width: 60px;
  --swiper-pagination-bullet-height: 2px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: #D9D9D9;
  --swiper-pagination-color: #{$color-primary-500};
  --swiper-navigation-size: 16px;

  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  position: absolute;
  bottom: 5%;
  right: 16%;

  .news-slider__buttons {
    flex-shrink: 0;
  }
}

.testimonial-pagination {
  position: static;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: auto !important;

  .swiper-pagination-bullet {
    font-size: 0;
    color: transparent;
    transition: background .3s ease-in-out;
  }
}


.testimonial__buttons {
  display: flex;
  gap: 20px;

}

.testimonial__button {
  @extend %buttonreset;
  position: static;
  width: 22px;
  height: 22px;
  color: $color-primary-700;

  &:after {
    display: none;
  }

  .testimonial__button-icon {
    width: 100%;
    height: 100%;
  }
}


@media (min-width: $bp-m) {
  .testimonial {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .testimonial__media {
    width: 42%;
    flex-shrink:0;
  }

  .testimonial__cite {
    gap:20px;
  }

  .testimonial__position {
    text-transform: uppercase;
  }
}