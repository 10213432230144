@use '../../abstracts/' as *;

.accordion-item {
    border-top:1px solid $color-neutral-500;

    &:last-child {
        border-bottom:1px solid $color-neutral-500;
    }
}

.accordion-item__title {
    font-size:1rem;
    line-height: normal;
}

.accordion-item__toggle {
    @extend %buttonreset;
    position:relative;
    width: 100%;
    padding:24px 0 24px;
    font-family: $font-primary-light;
    font-size:1.25rem;
    text-align: left;
    color:$color-primary-700;

    &:before, &:after {
        @include pseudo();
        background:currentColor;
    }

    &:before {
        top:24px;
        right:10px;
        width:1px;
        height:20px;
        transform:rotate(0);
        will-change: transform;
        transition: transform .3s ease-in-out;
    }

    &:after {
        top:34px;
        right:0;
        width:20px;
        height:1px;
    }

    &.active:before {
        transform:rotate(90deg);
    }
}

.accordion-item__content {
    display: none;
    padding-bottom:24px;
    font-family: $font-primary-light;
    font-size:1rem;
    color:#363C41;
}


@media(min-width:$bp-l){
    .accordion-item__toggle {
        font-size:1.5rem;
        color:$color-primary-500;
    }
}