@use '../abstracts' as *;

table {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-bottom: 1rem;
	background-color: transparent;

	thead th {
		border-right:30px solid transparent;
		padding:0 30px 16px 0;
		border-bottom:2px solid $color-primary-700;

		&:last-child {
			border-right:0;
		}
	}
	
	th {
		font-family: $font-primary-bold;
		font-size:0.875rem;
		text-transform: uppercase;
		text-align: left;
		color:$color-primary-700;
	}

	td {
		border-bottom:1px solid #868A9F;
		border-right:30px solid transparent;
		padding:30px 0 28px;

		&:last-child {
			border-right:0;
		}
	}
}