@use '../../abstracts/' as *;

.stats-grid {
    background: $color-primary-500;
    padding: 25px;
}

.stats-grid__stat, .stats-grid__subheading, .stats-grid__summary {
    color: $color-white;
}

.stats-grid__item {
   padding: 10px 0;
   &:nth-child(2n){
    width: 75%;
    margin-left: auto;
   }
}

.stats-grid__subheading {
    font-family: $font-primary-bold;
    font-size:#{"clamp(1.125rem, 1.3vw, 1.5rem)"};
    letter-spacing: .5px;
}

.stats-grid__stat {
    font-size:#{"clamp(4.0625rem, 5.8vw, 100px)"};
    font-family: $font-primary-thin;
}

.stat-grid__summary {
    font-family: $font-primary-medium;
}

@media(min-width: $bp-mw) {
    .stats-grid {
        column-count: 2;
        column-rule: 1px solid $color-white;
        column-gap: 105px;
    }

    .stats-grid__item {
        display: inline-block;
        padding: 60px 0;
        width: 100%;
        &:first-child {
            padding-top: 0;
        }

        &:nth-child(odd) {
            width:100%;
            margin-bottom:10px;
            border-bottom:1px solid #fff;
        }
    
    }
}