/* ================================================================
   Variables
================================================================ */

// Color Variables
$color-black: #000;
$color-white:#fff;

$color-neutral-25: #fbfbfa;  
$color-neutral-50: #f9f9f8;     
$color-neutral-100: #f2f1f0;
$color-neutral-200: #f2f1f0;
$color-neutral-300: #f2f1f0;
$color-neutral-400: #dad8d8;
$color-neutral-500: #939393;
$color-neutral-600: #5c5d5e;
$color-neutral-700: #525c70;
$color-neutral-800: #303131;
$color-neutral-900: #000000;

$color-primary-50: #eef3da;
$color-primary-100: #BACDA7;
$color-primary-200: #BACDA7;
$color-primary-300: #BACDA7;
$color-primary-400: #ACC486; 
$color-primary-500: #799c4b;
$color-primary-600: #799c4b;
$color-primary-700: #658d30;
$color-primary-800: #497C20;
$color-primary-900: #295C03;

$color-secondary-50:#E4DFFA;
$color-secondary-100: #BCB2F1;
$color-secondary-200: #AC9FF0;
$color-secondary-300: #775EE6;
$color-secondary-400: #4B39C5;
$color-secondary-500: #000F9F;
$color-secondary-600: #000F9F;
$color-secondary-700: #080866;
$color-secondary-800: #060C46;
$color-secondary-900: #060C46;


$color-tertiary-1:#D7E6F5;
$color-tertiary-2: #E4F1FB;
$color-tertiary-3: #EBF4FB;
$color-tertiary-4: #F2F1F0;
$color-tertiary-5: #F7F7F7;
$color-tertiary-6: #CFDEAB;
$color-tertiary-7: #F9FCEC;
$color-tertiary-8: #EEF3DA;
$color-tertiary-9: $color-black;
$color-tertiary-10: #A2AAAD;
$color-tertiary-11: #B1CA6F;

$body-bg: #fff;
$color-base: $color-neutral-900;
$color-invert: #fff;
$color-primary:$color-primary-700;
$color-secondary:#f5f5f5;
$color-success-1:#D7EEC8;
$color-success-2: #50B213;
$color-warning-1: #FFF3BC;
$color-warning-2: #F8CD35;
$color-error-1: #FFE1D1;
$color-error-2: #F04438;
$color-lime: #D6DE23;

$colors: (
    "neutral-25":$color-neutral-25,
    "neutral-50":$color-neutral-50,
    "neutral-100":$color-neutral-100,
    "neutral-200":$color-neutral-200,
    "neutral-300":$color-neutral-300,
    "neutral-400":$color-neutral-400,
    "neutral-500": $color-neutral-500,
    "neutral-600":$color-neutral-600,
    "neutral-700":$color-neutral-700,
    "neutral-800":$color-neutral-800,
    "neutral-900":$color-neutral-900,
    "primary-50":$color-primary-50,
    "primary-100":$color-primary-100,
    "primary-200":$color-primary-200,
    "primary-300":$color-primary-300,
    "primary-400":$color-primary-400,
    "primary-500": $color-primary-500,
    "primary-600":$color-primary-600,
    "primary-700":$color-primary-700,
    "primary-800":$color-primary-800,
    "primary-900":$color-primary-900,
    "secondary-50":$color-secondary-50,
    "secondary-100":$color-secondary-100,
    "secondary-200":$color-secondary-200,
    "secondary-300":$color-secondary-300,
    "secondary-400":$color-secondary-400,
    "secondary-500": $color-secondary-500,
    "secondary-600":$color-secondary-600,
    "secondary-700":$color-secondary-700,
    "secondary-800":$color-secondary-800,
    "secondary-900":$color-secondary-900,
    "tertiary-1":$color-tertiary-1,
    "tertiary-2":$color-tertiary-2,
    "tertiary-3":$color-tertiary-3,
    "tertiary-4":$color-tertiary-4,
    "tertiary-5":$color-tertiary-5,
    "tertiary-6":$color-tertiary-6,
    "tertiary-7":$color-tertiary-7,
    "tertiary-8":$color-tertiary-8,
    "tertiary-9":$color-tertiary-9,
    "tertiary-10":$color-tertiary-10,
    "tertiary-11":$color-tertiary-11,
    "success-1":$color-success-1,
    "success-2":$color-success-2,
    "warning-1":$color-warning-1,
    "warning-2":$color-warning-2,
    "error-1":$color-error-1,
    "error-2":$color-error-2,
    "white":$color-white,
    "black":$color-black
);


// Typography
$font-primary-thin:'Space Grotesk', sans-serif; // 200
$font-primary-light:'Mulish', sans-serif; // 300
$font-primary:'Mulish', sans-serif; // 400
$font-primary-medium:'Mulish', sans-serif; // 500/600
$font-primary-bold:'Mulish', sans-serif; // 700
$font-secondary: $font-primary;
$font-heading: $font-primary-bold;

$fontWeight-light: 200;
$fontWeight-normal: 300;
$fontWeight-medium: 400;
$fontWeight-semibold: 600;
$fontWeight-bold: 800;

$typography-margin-bottom: 1em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 72em; // 1152px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 85.3125em; // 1365px
$bp-xxl: 87.5em; // 1439px
$bp-xxxl: 103.75em; // 1600px

$breakpoints: (
    xs: 0, 
    sm: $bp-s, 
    md: $bp-m,   
    lg: $bp-l, 
    xl: $bp-xl,
    xxxl: $bp-xxl
);

$grid-container-width:90em;

// Navigation Variables
$nav-transition-speed: 0.4s;
$nav-barBackground: #333;
$nav-mainLinkBackground: #666;
$nav-subLinkBackground: #999;


// Grid
$grid-gutter-em: 0.938em;

// Typography
$base-font-size:16px;

// Images
$img-path: '/dist/images/';