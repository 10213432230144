@use '../../abstracts/' as *;

.site-header {
    position: sticky;
    top:0;
    width:100%;
    z-index: 100;
    background:$color-neutral-100;

    .main-nav-open {
        position:absolute;
    }

    &.sticky {
        box-shadow: 0 2px 4px rgba(0,0,0,.1);
    }
}

.header-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:20px;
}

.header-col {
    &.header-col--util {
        display: flex;

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-left:1px solid $color-neutral-500; 
        }
    }

    &.header-col--logo {
        padding-right:20px;

        img {
            position:relative;
            z-index:10;
            filter: none;
            transition:filter .3s ease-in-out;
        }
    }

    .main-nav-open &.header-col--logo img {
        filter: brightness(100) saturate(0%);
    }
}

.site-search__toggle,
.main-nav-toggle {
    @extend %buttonreset;
    width: 80px;
    height: 80px;
}

.main-nav {
    display: none;
}

@media(min-width:$bp-l){
    .header-layout {
        padding-left:40px;
    }
}