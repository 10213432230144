@use '../../abstracts/' as *;

.card.card--arrow {
    display: flex;
    align-items: center;
    gap:30px;
    border-radius: 0;
    box-shadow: none;

    .card__link {
        display: flex;
        align-items: center;
        gap:30px;
        width:100%;
        padding:26px 30px;
        background:transparent;
        will-change: background;
        transition:background .3s ease-in-out;
        color:$color-primary;

        &:hover, &:focus-visible {
            background:#faf9f9;
            text-decoration: none;
        }
    }

    .card__media {
        max-width:45px;
    }

    .card__title {
        font-family: $font-primary-light;
        font-size:#{"clamp(1.5rem, 1.7vw, 1.875rem)"};
    }

    .card__content {
        display: flex;
        align-items: center;
        gap:10px;
        font-size:#{"clamp(1.125rem, 1.3vw, 1.5rem)"};
    }

    .card__icon {
        width:12px;
        height:12px;
        color:$color-primary-300;
        transform: none;
        transition: transform .3s ease-in-out;
    }
}

.card-arrow-list .card--arrow {
    padding:26px 30px;
    border-bottom:1px solid $color-neutral-300;

    &:has(.card__link) {
        padding:0;
    }

    &:last-child {
        border-bottom:0;
    }
}

@media(min-width:$bp-l){
    .card.card--arrow {
        padding:26px 30px;

        .card__media {
            max-width:70px;
        }

        .card__body {
            display: flex;
        }

        .card__link {
            display: grid;
            grid-template-columns: 70px 1fr;
            gap:40px;
            padding:18px 24px;
        }
        
        .card__content {
            margin-left:auto;
            align-items: flex-end;

            .clearfix {
                max-width:150px;
            }
        }

        .card__icon {
            width:24px;
            height:24px;
        }

        &:hover, &:focus-within {
            .card__icon {
                transform:translateX(12px);
            }
        }
    }
}