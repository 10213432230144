@use '../../abstracts/' as *;

// Product search box
.hero + .product-searchbox-wrap {
    position: relative;
    margin-top:-43px;
}

.product-searchbox__label {
    border-top-right-radius: 50px;
    margin-right:24px;
    padding:13px 0 13px 60px;
    background:$color-primary-300;
    font-family: $font-primary-bold;
    font-size:0.75rem;
    letter-spacing: .3px;
    color:$color-white;
}

.product-searchbox__controls {
    padding: 27px 24px;
    background:$color-primary-700;
    color:$color-white;
}

.product-searchbox__controls-inner {
    position:relative;
}

input[type="text"].product-searchbox__input,
input[type="search"].product-searchbox__input {
    height:auto;
    border-bottom:1px solid $color-white;
    padding-right:35px;
    padding-bottom:10px;
    background:none;
    font-family: $font-primary-light;
    font-size:1.25rem;
    line-height:1.3;
    color:currentColor;

    &::placeholder {
        color:currentColor;
    }

    &:hover {
        background:none;
    }

    &:focus {
        border-bottom:1px solid $color-white;
        background:none;
    }
}

button.product-searchbox__submit {
    @extend %buttonreset;
    position: absolute;
    bottom:10px;
    right:0;
    border-radius: 0;
    color:currentColor;
}

.product-searchbox__icon {
    width:25px;
    height:25px;
    fill: currentColor;
}

@media(min-width:$bp-m){
    .hero + .product-searchbox-wrap {
        margin-top:-84px;
    }

    .product-searchbox {
        display: grid;
        grid-template-columns: 25% 1fr;
    }

    .product-searchbox__label {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 54px;
        border-top-right-radius: 0;
        margin-right:0;
        padding:0 10px;
        font-family: $font-primary-bold;
        font-size:0.875rem;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        text-align: center;
    }

    .product-searchbox__controls {
        padding:6px 32px 25px;
    }

    input[type="text"].product-searchbox__input,
    input[type="search"].product-searchbox__input {
        font-size:clamp(17px, 1.35vw, 1.625rem);
    }
}

@media(min-width:$bp-l){
    .product-searchbox {
        grid-template-columns: 15% 1fr;
        max-width: 90%;
        margin-left: auto;
    }

    .product-searchbox__controls-inner {
        width:82%;
    }
}