@use '../../abstracts/' as *;


.card.card--icon-white {
    border-radius: 50px 0 50px 0;
    padding: 3rem 3rem 1rem;
    text-align: center;
    position: relative;
    overflow: visible;
    margin-bottom: 65px;
    box-shadow: 0 25px 50px -12px rgba(0,0,0,.25);

    &:after {
        display:none;
    }
    strong{
        color:$color-primary-600;
    }

    .card__body {
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:1rem;
    }

    .card__title {
        font-size:1.5rem;
        font-family:$font-primary-light;
        line-height: 1.3;
    }

    .card__summary {
        color:$color-primary-700;
    }

    .card__action {
        align-self:flex-end;
    }

    &.swiper-slide {
        border-radius: 0;
        padding:0 24px 15px;
        box-shadow: none;
        background:none;
        
        .card__body {
            height:100%;
            border-radius: 50px 0 50px 0;
            padding:32px;
            box-shadow: 4px 4px 15px rgba(0,0,0,.1);
            background:$color-white;
    
            .card__action {
                margin-top:auto;
            }
        }
    }
}

@media(min-width:$bp-mw){
    .card-icon-wrapper{
        margin: 50px 0 0 50px;
    }

    .card.card--icon-white {
        border-radius: 0 0 50px 0;
        box-shadow: none;
        text-align: left;
        padding: 3rem 3rem 1rem 5rem;
        .card__media{
            height: 115px;
            left: -50px;
            position: absolute;
            top: -50px;
            width: 115px;
        }
    }
    
}