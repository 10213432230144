@use '../../abstracts/' as *;

.news-list {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.news-item__media img {
    width:100%;
}

.news-item__body {
    display: flex;
    flex-direction: column;
    gap:24px;
    padding:40px 32px;
    background:$color-white;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.news-item__topics {
    display: flex;
    gap:8px;
    flex-wrap:wrap;
    font-size:#{"clamp(0.75rem, 5vw, 0.875rem)"};
}

.news-item__title {
    font-size:#{"clamp(1.25rem, 5vw, 1.5rem)"};

    .news-item__link {
        color:currentColor;
    }
}

.news-item__content {
    font-size: #{"clamp(1rem, 5vw, 1.25rem)"};
}

.news-item__meta {
    display: flex;
    align-items: center;
    gap:5px;

    .news-item__link {
        margin-left:auto;
    }
}

.news-item__date {
    display: flex;
    align-items: center;
    gap:10px;
    font-size:0.875rem;
    letter-spacing: 1px;
}

.news-item__date-icon {
    width:20px;
    height:20px;
}

.news-item.news-item--card {
    display: block;

    .news-item__media {
        position: relative;
        height:148px;
        overflow: hidden;

        img {
            position: absolute;
            max-width:none;
            min-width:100%;
            height:100%;
            object-fit: cover;
        }
    }

    .news-item__body {
        padding:32px;
        gap:16px;
    }

    .news-item__title {
        font-size:1rem;
    }

    .news-item__meta {
        display: flex;
        margin-top:20px;
    }
}

@media(min-width:$bp-l){
    .news-item {
        display: grid;
        grid-template-columns: 46% 1fr;
    }

    .news-item__media {
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            max-width:none;
            min-width:100%;
            height:100%;
            object-fit: cover;
        }
    }

    .news-item__body {
        gap:40px;
        padding:80px 102px 70px 80px;
    }

    .news-item__meta {
        display: none;
    }

    .news-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap:30px;

        .news-item {
            display: block;
        }

        .news-item__media img {
            position: static;
        }

        .news-item__body {
            padding:40px 32px;
            gap:24px;
        }
    }

    .news-item.news-item--card {
        .news-item__media {
            height:267px;
        }

        .news-item__title {
            font-size:1.5rem;
        }
    }
}