@use '../abstracts/' as *;

.utility-nav {
    display: none;
}

.utility-nav__menu {
    display: flex;
    align-items: center;
    margin:0;
    padding:0;
    list-style-type: none;
}

.utility-nav__link {
    font-family:$font-primary;
    font-size:1.02rem;
    text-decoration: none;
    text-transform: none;
    color:$color-black;
    font-weight:600;
}

@media(min-width:$bp-l){
    .utility-nav {
        display: block;
    }
    
    .utility-nav__menu {
        gap:50px;
    }
}

@media(min-width:$bp-xxl){
    .utility-nav__menu {
        gap:110px;
    }
}