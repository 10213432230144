@use '../../abstracts/' as *;
@use 'sass:math';

.card.card--grayBackground {
    border-radius: 0;
    padding:0;
    box-shadow: none;
    overflow: visible;
    position: relative;
    background:none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card__media {
        background:no-repeat center/cover;
        position: relative;
        width: 100%;
        z-index: 1;
        &::after{
            content: "";
            display: block;
            padding-top: math.percentage(math.div(532,723));
          }
    }
    .card__title {
        z-index: 3;
        position: relative;
        padding:2.5rem 1.5rem;
        font-size: clamp(2.75rem,6vw,3.375rem);
    }

    .card__body {
        padding:0;
        z-index: 2;
        position: relative;
    }
    .card__body-inner {
        background: $color-secondary;
        border-bottom-right-radius: 50px;
        margin-top: -9%;
        padding: 3rem 2rem;
        z-index: 4;
        position: relative;
        width: 92%;
     }

    .card__subheading {
        font-size:#{"clamp(1.875rem, 5vw, 2.5rem)"};
        color:$color-primary-700;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
    .button{
        font-family:$font-primary-medium;
    }
}

@media(min-width:$bp-mw){
    .card.card--grayBackground {
        position:relative;
       
        &::before{
            display: none;
        }
        .card__body {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            gap:5%;
            padding:9% 4.5% 0;
            background: $color-secondary;
            margin-left: 10%;
            margin-bottom: 12%;
            &::before{
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 30px 30px;
                top: -40px;
                content: "";
                display: block;
                height: 114px;
                position: absolute;
                left: -35px;
                width: 108px;
                z-index: 1;
            }
        }

        .card__title {
            font-size: 4rem;
            margin-left: 10%;
            text-align: left;
            margin-bottom: -4%;
            z-index: 3;
            position: relative;
            padding: 0px 1rem;
            width: 60%;
        }

        .card__body-inner {
            padding: 0 0 5rem;
            width: 80%;
            margin-top: 0;
        }
        .card__media--wrapper{
            width: 100%;
            height: 111%;
            margin-bottom: -10%;
        }
        .card__media{
            border-bottom-left-radius: 50px;
        }
        .card__summary{
            font-size: clamp(1.5rem,6vw,1.25rem);
            letter-spacing: 0.24px;
        }
    
    }
}

@media(min-width:$bp-xxxl){
    .card.card--grayBackground {
        .card__title {
            font-size: 5rem;
        }
    }
}
