@use '../../abstracts/' as *;

.card.card--standard {
    border-radius: 0;
    padding:0;
    box-shadow: none;

    &:after {
        display:none;
    }
}