@use '../../abstracts/' as *;

.card--landscape-blue-overlap-wrapper{
    padding: 0;
}

.card.card--landscape-blue-overlap {
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    padding: 0 0 3rem;
    background: none;

    .card__title {
        position:relative;
        border-bottom-right-radius: 50px;
        margin-top:-24px;
        margin-right:24px;
        padding:45px 34px 60px;
        background:linear-gradient(110deg, #1662CC 20.25%, #3492EA 87.38%);
        font-size:#{"clamp(2.75rem, 4.6vw, 5rem)"};
        line-height: 1.2;
        color:$color-white;
    }

    .card__body {
        margin-top:42px;
        padding:0 1.875rem;
    }

    .card__subheading {
        font-size:#{"clamp(1.875rem, 2.3vw, 2.5rem)"};
        color:$color-primary-700;
    }

    .card__content {
        font-family: $font-primary-light;

        & > *:last-child {
            margin-bottom:0;
            padding-bottom:0;
        }
    }

    &:after {
        display:none;
    }
}

@media(min-width:$bp-mw){
    .card.card--landscape-blue-overlap {
        position:relative;
        display: inline-grid;
        grid-template-columns: 52.5% 1fr;
        align-items: start;
        gap:5%;

        .card__media,
        .card__img {
            position:absolute;
            top:0;
            z-index:0;
        }

        .card__media {
            width:45%;
            max-width:783px;
            height: 100%;
            max-height:689px;
            .card__media--inside{
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
            &:before {
                background-image: radial-gradient(#091070 5%,transparent 7%);
                background-position: 0 0;
                background-size: 30px 30px;
                content: "";
                display: block;
                height: 114px;
                position: absolute;
                right: -35px;
                bottom: -40px;
                width: 108px;
                z-index: 1;
            }
        }
        picture{
            width: 100%;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 2;
        }
        }

        .card__img {
            left:0;
            min-width:100%;
            height:100%;
            max-width:none;
            object-fit: cover;
        }

        .card__title {
            border-bottom-right-radius: 0;
            border-top-right-radius: 50px;
            margin:85px 0 0 25%;
            padding:9%;
        }

        .card__body {
            margin: 3.5rem 0;
            padding: 0;
        }

        .card__subheading {

            &:after {
                @include pseudo(block, static, '');
                width:50px;
                height:2px;
                margin:40px 0;
                background:#3B8EDE;
            }
        }
    }
}

@media(min-width:$bp-xxxl){
    .card.card--landscape-blue-overlap {
        gap: 4%;
        grid-template-columns: 56% 1fr;
        align-items: center;

        .card__title {
            margin-top:0;
            padding:70px;
        }
    }
}
